import * as wj from '../wjtools'
import directive from './directive'

export default {
  report(params) {
    if (this.options.debug) return // 非线上环境不发送统计
    params = typeof params === 'string' ? { name: params } : params

    // 通过 Image 对象请求统计链接
    let img = new Image(1, 1)
    let url = this.options.url || '//log.kjhaoyun.com/log-kgj.gif'
    let action = params.action || '_点击功能按钮'
    let userId = wj.store('userInfo') && wj.store('userInfo').userId
    let username = wj.store('userInfo') && wj.store('userInfo').name
    img.src = wj.setParams(url, {
      name: document.title + action, // 上报名称
      source: '14',
      event: 'click', // 触发事件
      user: username, // 用户手机号
      user_id: userId, // 用户Id
      clientid: wj.cookie('clientid'), // 客户端标识
      channelid: wj.session('channelid'), // 外链渠道标识
      msg: '', // 额外信息
      page: document.URL, // 当前页面
      page_title: document.title, // 当前页面名称
      time: Date.now(), // 时间
      // location: JSON.stringify(wj.store('location')), // 用户所属城市
      // from_path: JSON.stringify(wj.session('frompath')), // 页面来源
      ...this.options,
      ...params,
    })
  },

  install(Vue, options) {
    if (this.install.installed) return

    // 设置用户配置
    this.options = typeof options === 'string' ? { url: options } : options

    // 缓存来源渠道标识
    let channelid = wj.getParam('channelId')
    // if (channelid && !wj.session('channelid')) {
    if (channelid) {
      wj.session('channelid', channelid)
    }

    // 缓存客户端标识
    if (!wj.cookie('clientid')) {
      // let clientid = Date.now() + '@' + wj.session('channelid')
      let clientid = Date.now() + '@' + channelid
      wj.cookie('clientid', clientid, { expires: 365 })
    }

    this.install.installed = true

    // add $report to Vue prototype
    Object.defineProperty(Vue.prototype, '$istat', {
      get: () => this,
    })

    Vue.directive('report', directive)
  },
}
