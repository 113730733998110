export default class ConversationObj {
  constructor(item) {
    // 后台通过不同的userType来用不同的参数表示未读数
    // let userType = localStorage.getItem('user-type')
    // 解析最后一条消息的消息格式 1-系统,2-文字,3-图片,4-语音,5-文件,6-视频
    let msgObj = JSON.parse(item.lastMessage || '{}')
    let obj = {
      conversationInfo: {
        lastMessage: {
          from: '',
          content: {
            mentionedType: 0,
            type: msgObj.msgType || 2,
            content: '',
            searchableContent: (msgObj.content && msgObj.content.text.content && msgObj.content.text.content.searchableContent) || '',
            pushContent: '',
            binaryContent: '',
            mediaType: 0,
            remoteMediaUrl: '',
          },
          messageId: item.id || 0,
          direction: 0,
          status: 1,
          messageUid: 0,
          timestamp: item.lastMessageTime || new Date().getTime(),
          conversationType: 0,
          target: item.id || '',
          line: 0,
        },
        timestamp: item.lastMessageTime || new Date().getTime(),
        draft: '',
        unreadCount: {
          unread: item.fromUnreadNum,
          unreadMention: 0,
          unreadMentionAll: 0,
        },
        readTimestamp: item.adverseReadTime || new Date().getTime(),
        isTop: false,
        isSilent: false,
        conversationType: 0,
        target: item.id || '',
        line: 0,
        top: false,
        slient: false,
        toId: item.toId,
      },
      name: item.toName || '',
      markName: item.toMarkName || item.toName || '',
      img: item.toHeadIcon || '',
      type: item.type || '', // 在服务商端 分辨聊天对象是客户还是运力商
      isTop: item.isTop,
      imStatus: item.imStatus,
      id: item.id,
    }
    return obj
  }
}
