export default class MessagesObj {
  // 1-系统,2-文字,3-图片,4-语音,5-文件,6-视频
  constructor(list) {
    let fromId = sessionStorage.getItem('from-id') || 0
    let toId = sessionStorage.getItem('friend-id') || 0
    let toName = (+list[0].toId === +toId) ? list[0].toName : list[0].fromName
    let obj = {
      name: toName || '',
      protoMessages: [],
      target: list[0].sessionId || 0,
    }
    let arr = []
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      let object = JSON.parse(item.content).text
      if (!object.content) {
        continue
      }
      let textObj = {}
      try {
        textObj = JSON.parse(item.content).text
      } catch (err) {
        textObj = {}
      }
      object.status = textObj.status || 1
      object.fromId = item.fromId
      object.toId = item.toId
      object.toName = item.toName
      object.fromName = item.fromName
      if (fromId) {
        if (item.fromId + '' === fromId) {
          object.direction = 0
        } else {
          object.direction = 1
        }
      } else {
        if (item.toId + '' === toId) {
          object.direction = 0
        } else {
          object.direction = 1
        }
      }

      arr.push(object)
    }
    obj.protoMessages = arr
    return obj
  }
}
