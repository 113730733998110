import dayjs from 'dayjs'
import { isDate, formatDate } from '@/common/wjtools/util'

export const filters = {
  // 文字超出截断
  wordSlice(word, length) {
    if (!word || typeof value !== 'string') return word
    return word.length > length ? word.slice(0, length - 1) + '...' : word
  },

  // 分转元
  fen2yuan(value) {
    return !isNaN(value) ? (value / 100).toFixed(2) : value
  },

  // 去掉货币符号
  delyuan(value) {
    return typeof value === 'string' ? Number(value.replace('￥', '')) : 0
  },

  // 格式化银行卡号
  fmtBankcard(value) {
    if (value && typeof value === 'string') {
      value = value.slice(0, 4) + ' *** *** ' + value.slice(-4)
    }
    return value
  },

  // 附件地址指向本地，通过服务器反向代理
  proxyFile(url, path = 'customerfile') {
    // if (location.hostname === 'localhost') return url
    // return url && url.replace(/^(https?:)?\/\/yungehuo\.oss-cn-shenzhen\.aliyuncs\.com\/customerfile\//i, '/customerfile/')
    return url && url.replace(new RegExp(`^(https?:)?//yungehuo\\.oss-cn-shenzhen\\.aliyuncs\\.com/${path}/`, 'i'), `/${path}/`)
  },

  /**
   * 功能：将时间戳按照给定的时间/日期格式进行处理
   * @param {Number} date 时间戳
   * @param {String} fmt 时间格式 'YYYY-MM-DD HH:mm:ss'
   * @returns {String} 规范后的 时间/日期 字符串
   */
  // fmtDate: (time, fmt) => formatDate(time, fmt),
  fmtDate(time, fmt = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) return time

    // 兼容 iOS 系统 2016-09-18T08:01:01.000+0000 => 2016-09-18T08:01:01.000+00:00
    if (typeof time === 'string') time = time.replace(/(\+\d{2})(\d{2})$/, '$1:$2')

    return dayjs(time).format(fmt)
  },

  // 时间戳格式化为时分秒（00:00:00）
  sec2time(sec, fmt = '00:00') {
    if (!sec || sec <= 0) return fmt
    // sec = Math.floor(sec)
    let h = String(Math.floor(sec / 3600)).padStart(2, '0')
    let m = String(Math.floor(sec / 60) % 60).padStart(2, '0')
    let s = String(sec % 60).padStart(2, '0')
    if (h > 0) {
      return `${h}:${m}:${s}`
    } else {
      return `${m}:${s}`
    }
  },

  // 时分秒（00:00:00）转为时间戳
  time2sec(time) {
    if (!time) return ''
    let h = time.split(':')[0]
    let m = time.split(':')[1]
    let s = time.split(':')[2]
    return Number(h * 3600) + Number(m * 60) + Number(s)
  },

  dateFormat(date) {
    if (!date) return '--'
    if (!isDate(date)) {
      date = new Date(date)
    }
    return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate()
  },

  // 时间对象数组转字符串
  formatDates(dates, separator = '-') {
    if (!Array.isArray(dates)) return dates
    return dates.filter(date => date).map(date => formatDate(date)).join(separator)
  },

  daterangeFormat(startDate, endDate) {
    if (startDate && endDate) {
      return filters.dateFormat(startDate) + '-' + filters.dateFormat(endDate)
    } else if (startDate) {
      return filters.dateFormat(startDate)
    } else if (endDate) {
      return filters.dateFormat(endDate)
    } else {
      return '--'
    }
  },

  getCity(addrsss) {
    if (!addrsss || typeof addrsss !== 'string') return addrsss
    return addrsss.replace(/^(?:.+(?:省|自治区))?(.+?)(?:市|特别行政区|区|县|自治县).*/, '$1') || addrsss.replace(/^(.+?)(?:省|自治区).*/, '$1')
  },

  // 获取 URL 的路径
  getPath(url) {
    if (typeof url !== 'string') return '/'
    // let ret = url.match(/(?<!\/)\/(?!\/).*/i) // 苹果系统不支持
    // return ret === null ? '/' : ret[0]
    url = url.replace('http://http://', '//') // 兼容错误地址
    let ret = url.replace(/^(?:http[s]?:)?\/\/[\w-.]+(\/.*)?/i, '$1')
    return ret || '/'
  },

  // 隐藏手机号中间四位
  formatMobile(num) {
    return (num || '').toString().replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
  },

  // 数字每3位加逗号
  numFormat(num) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  },

  // 数字转千位如：2（K）
  num2Kilo(num) {
    let n = +num
    if (!n || n < 100) return num
    return (n / 1000).toFixed(2)
  },

  // 格式化订单状态
  fmtOrderStatus(v) {
    switch (+v) {
      case 0:
        return '待付款'
      case 1:
        return '待发货'
      case 2:
        return '已发货'
      case 3:
        return '已完成'
      case 4:
        return '已关闭'
      default:
        return ''
    }
  },

  // 标记数字值
  badgeNumber(num, max = 99) {
    return num > 99 ? '99+' : num
  },
}

export default Vue => {
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
}
