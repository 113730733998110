import * as api from '@/api'
import ConversationObj from '@/webrtc/conversation'
import * as wj from '@/common/wjtools'
import MessagesObj from '@/webrtc/messagesObj'
let inputingTimer = null

export default {
  // async getCountryList({ commit }, isFba) {
  //   const res = await api.getCountryList({ fba: isFba ? 1 : 0 })
  //   if (res.code !== 200) {
  //     // this.$message.error(res.message)
  //     return
  //   }
  //   let list = (res.data || []).map(item => ({ ...item, name: item.nameCn }))
  //   commit('setCountryList', list)
  // },https://test-chat.kjhaoyun.com/chat-service?token=759db314-a78c-45b7-be31-8a08ac47c89b&isService=true&userType=2&toType=1

  async search({ state, commit }, value) {
    // 搜索默认从第一页开始
    let pageInfo = {
      page: 1,
      size: 10,
    }
    commit('setChatListPageInfo', pageInfo)
    let params = {
      ...state.chartListPageInfo,
      name: value,
    }
    commit('setConversations', [])
    // 获取并设置会话列表
    const res = await api.getConversationListByPage(params)
    // 获取并设置会话列表
    if (res.code === '200') {
      let list = res.data.records || []
      if (list.length === 0) {
        commit('changeEmptyMessageState', true)
      }
      list = list.map((item) => new ConversationObj(item))
      commit('setConversations', list)
      // 判断是否没有更多
      commit('setChatListNomore', state.conversations.length >= res.data.total)
    } else {
      commit('changeEmptyMessageState', true)
    }
    commit('search', value)
    // setTimeout(() => , 100)
  },

  selectConversation: async ({ commit, dispatch }, value) => {
    // console.log('选择会话')
    commit('selectConversation', value)
    let param = {
      sessionId: value.target,
    }
    value.target && await api.clearUnreadNum(param)
    // 获取会话信息并进行比对
    let params = {
      sessionId: value.target,
      limit: 10,
    }
    dispatch('getConversationHistory', params)
  },
  async getConversationHistory({ commit }, params) {
    // 获取历史会话记录(聊天数据)
    let res = await api.getConversationHistory(params)
    if (res.code === '200') {
      let msgList = res.data
      if (msgList.length > 0) {
        // 如果信息聊天有内容
        let target = msgList[0].sessionId
        // seqId
        let seqId = msgList[msgList.length - 1].seqId
        let localMessages = JSON.parse(localStorage.getItem('messages') || '[]')
        let hasMatch = false
        msgList.reverse()
        if (localMessages.length > 0) {
          commit('setSeqId', seqId)
          // 通过接口返回的数据进行替换
          localMessages.map((item, index) => {
            if (item.target === target) {
              hasMatch = true
              let resLastMessageId = JSON.parse(msgList[msgList.length - 1].content).text.messageId
              let localLastMessageId = item.protoMessages[item.protoMessages.length - 1].messageId
              if (resLastMessageId !== localLastMessageId) {
                localMessages[index] = new MessagesObj(msgList)
                commit('setSeqId', { seqId: seqId, isUpdate: true })
              }
            }
          })
          if (!hasMatch) {
            let msgObj = new MessagesObj(msgList)
            localMessages.push(msgObj)
          }
        } else {
          localMessages = []
          let msgObj = new MessagesObj(msgList)
          localMessages.push(msgObj)
          commit('setSeqId', { seqId: seqId, isUpdate: true })
        }
        commit('setMessages', localMessages)
        // wj.storage('localStorage', 'messages', localMessages)
      } else {
        // console.log('没有数据')
      }
    } else {
      // console.log('接口崩了')
    }
  },
  clearUnreadStatus: async ({ state, commit }, value) => {
    // 增加接口请求
    let param = {
      sessionId: state.selectTarget,
    }
    if (state.selectTarget && state.selectTarget !== 'undefined') {
      let res = await api.clearUnreadNum(param)
      if (res.code === '200') {
        commit('clearUnreadStatus', value)
      }
    }
  },
  selectFriend: ({ commit }, value) => commit('selectFriend', value),
  updateFriendList: ({ commit }, value) => commit('updateFriendList', value),
  updateUserInfos: ({ commit }, value) => commit('updateUserInfos', value),
  sendMessage: ({ commit }, msg) => commit('sendMessage', msg),
  send: ({ commit }) => commit('send'),
  initData: ({ commit, dispatch }) => {
    dispatch('setUserInfo')
    dispatch('getConversations')
    commit('initData')
  },
  updateConversationInfo: ({ commit }, value) => commit('updateConversationInfo', value),
  updateConversationIntro: ({ commit }, value) => commit('updateConversationIntro', value),
  addProtoMessage: ({ commit }, value) => commit('addProtoMessage', value),
  logout: ({ commit }, value) => commit('logout', value),
  changetFirstLogin: ({ commit }, value) => commit('changetFirstLogin', value),
  getUploadToken: ({ commit }, value) => commit('getUploadToken', value),
  visibilityChange: ({ commit }, value) => commit('visibilityChange', value),
  searchUser: ({ commit }, value) => commit('searchUser', value),
  updateSearchUser: ({ commit }, value) => commit('updateSearchUser', value),
  sendFriendAddRequest: ({ commit }, value) => commit('sendFriendAddRequest', value),
  updateFriendRequest: ({ commit }, value) => commit('updateFriendRequest', value),
  handleFriendRequest: ({ commit }, value) => commit('handleFriendRequest', value),
  updateFriendIds: ({ commit }, value) => commit('updateFriendIds', value),
  modifyMyInfo: ({ commit }, value) => commit('modifyMyInfo', value),
  getUserInfos: ({ commit }, value) => commit('getUserInfos', value),
  updateGroupInfos: ({ commit }, value) => commit('updateGroupInfos', value),
  getGroupInfo: ({ commit }, value) => commit('getGroupInfo', value),
  getGroupMember: ({ commit }, value) => commit('getGroupMember', value),
  quitGroup: ({ commit }, value) => commit('quitGroup', value),
  updateTempGroupMember: ({ commit }, value) => commit('updateTempGroupMember', value),
  changeEmptyMessageState: ({ commit }, value) => commit('changeEmptyMessageState', value),
  updateProtoMessageUid: ({ commit }, value) => commit('updateProtoMessageUid', value),
  updateMessageStatus: ({ commit }, value) => commit('updateMessageStatus', value),
  updateMessageContent: ({ commit }, value) => commit('updateMessageContent', value),
  deleteConversation: ({ commit }, value) => commit('deleteConversation', value),
  deleteMessage: ({ commit }, value) => commit('deleteMessage', value),
  preAddProtoMessage: ({ commit }, value) => commit('preAddProtoMessage', value),
  updateSendMessage: ({ commit }, value) => commit('updateSendMessage', value),
  addOldMessage: ({ commit }, value) => commit('addOldMessage', value),

  // 获取会话列表
  getConversations: async ({ state, commit, dispatch }) => {
    // 如果URL中有渠道ID则先创建会话
    let targetId = state.shopId || state.userCode || state.supplierId
    if (state.imOrderType === '3') targetId = '3'
    if (state.imOrderType === '4') targetId = '4'
    if (state.imOrderType + '' === '6') targetId = '6'
    if (targetId) {
      let res
      let params = {
        targetId: targetId,
        userType: state.toType - 0,
      }
      if (state.shopId === '0') {
        // params.entrance = state.imOrderId ? '2' : '1'
        params.entrance = state.imOrderId ? '2' : '4'
        // 2 订单 1 方案
        const origin = {
          // 1: state.shopIdName,
          4: state.shopIdName,
          2: state.imOrderId,
        }
        params.origin = origin[params.entrance]
        res = await api.imSession(params)
      } else if (state.imOrderType === '3') { // 整柜
        params.entrance = '3'
        params.origin = state.origin // 这里面放了方案的信息
        params.targetId = targetId
        res = await api.imSession(params)
      } else if (state.imOrderType + '' === '4') { // 侧边栏
        params.targetId = '0'
        params.entrance = '4'
        params.origin = '侧边栏入口'
        params.userType = 999
        res = await api.imSession(params)
      } else if (state.imOrderType + '' === '6') { // 平台客服
        commit('setConversationType', 'service')
        res = await api.imSession({
          entrance: 6,
          origin: '官方客服入口',
        })
      } else {
        res = await api.createConversation(params)
      }
      if (res.code === '200') {
        commit('setSelectTarget', res.data.id)
        commit('setFirstSelectTarget', res.data.id) // 设置第一次 selectTarget
        commit('selectFriend', res.data.toId)
        let params = {
          sessionId: res.data.id,
          limit: 10,
        }
        dispatch('getConversationHistory', params)
        dispatch('clearUnreadStatus')
        if (state.sys) {
          api.crmNotify({
            btnName: '在线咨询',
            serverName: (state.shopId === '0' || !state.shopId) ? '跨境好运' : state.shopIdName,
            imToId: res.data.toId,
            imSessionId: res.data.id,
          })
        }
      }
    }
    let params = {
      ...state.chartListPageInfo,
    }
    // 获取并设置会话列表
    const res = await api.getConversationListByPage(params)
    // 获取并设置会话列表
    if (res.code !== '200') {
      return false
    }

    let list = (res.data && res.data.records) || []
    if (list.length === 0) {
      commit('changeEmptyMessageState', true)
    }
    // 唤起置顶
    if (targetId) {
      let index = list.findIndex(v => v.id === state.selectTarget)
      if (index !== -1 || index !== 0) {
        // let obj = JSON.parse(JSON.stringify(list[index]))
        // list.splice(index, 1)
        // list.unshift(obj)
        let conversation = list.splice(index, 1)[0]
        let hasTop = conversation.isTop
        let topIndex = 0 // 置顶的index
        list.map((conv, idx) => {
          conv.isTop && (topIndex = idx + 1)
        })
        list.splice(hasTop ? 0 : topIndex, 0, conversation)
      }
    }
    list = list.map((item) => new ConversationObj(item))
    let { isManageSign } = wj.getQuery(location.href)
    // 如果没有shopId 并且 没有supplierId 并且 他不是后台管理系统 默认开打第一个
    if (!targetId && !isManageSign && list[0]) {
      commit('selectConversation', list[0].conversationInfo)
      commit('selectFriend', list[0].conversationInfo.toId)
      commit('setSelectTarget', list[0].conversationInfo.target)
      let params = {
        sessionId: list[0].conversationInfo.target,
        limit: 10,
      }
      dispatch('getConversationHistory', params)
    }
    if (state.selectTarget) {
      let tatgetList = list.filter(v => v.id === state.selectTarget)
      if (tatgetList.length) {
        console.log(state.selectTarget)
        commit('setSelectTargetStatus', tatgetList[0].imStatus)
      }
    }
    // if (!sessionId) {
    //   list[0] && commit('selectConversation', list[0].conversationInfo)
    // }
    commit('setConversations', list)

    // 判断是否没有更多
    commit('setChatListNomore', state.conversations.length >= res.data.total)
  },

  // 创建并选择平台客服会话---暂时不用
  createServiceConv: async ({ state, commit, dispatch }) => {
    commit('initServiceData')
    commit('setConversationType', 'service') // 设置会话类型
    const res = await api.imSession({
      // targetId: state.selectTarget,
      // userType: state.toType - 0,
      entrance: 6, // 入口类型：1、方案入口；2、订单入口；6、官方客服入口
      origin: '官方客服入口', // 入口说明
    })

    if (+res.code !== 200) {
      // Toast.fail(res.msg)
      console.log(res.msg)
      return
    }

    // 建立会话后选择会话并获取聊天记录
    const conversation = res.data || {}
    const { id: sessionId, toId } = conversation
    if (!sessionId || !toId) {
      // Toast.fail('创建会话失败')
      console.log(res.msg)
    }
    commit('setSelectTarget', sessionId)
    commit('setServiceConversation', new ConversationObj(conversation))
    commit('setConversations', [new ConversationObj(conversation)])
    commit('selectFriend', toId)
    dispatch('getConversationHistory', { sessionId, limit: 10 }) // 获取聊天记录
  },

  getMarkName: async ({ state, commit }, val) => {
    let res = await api.getMarkName({ sessionId: val })
    if (+res.code === 200) {
      let oldMarkName = res.data.markName || this.selectedCov.name
      if (state.isServiceConversation) {
        oldMarkName = '平台客服'
      }
      commit('setOldMarkName', oldMarkName)
    } else {
      console.error(res.msg)
    }
  },

  // 推荐方案在线咨询 新建会话
  createChat: async ({ state, commit, dispatch }) => {
    let info = state.channelInfoChat
    let res
    let targetId = info.shopId || '0'
    let params = {
      targetId,
      userType: targetId === '0' ? '999' : '1',
    }
    if (targetId === '0') {
      // params.entrance = '1'
      params.entrance = '4'
      params.origin = info.channelName
      res = await api.imSession(params)
    } else {
      res = await api.createConversation(params)
    }
    if (res.code === '200') {
      commit('setSelectTarget', res.data.id)
      commit('setFirstSelectTarget', res.data.id) // 设置第一次 selectTarget
      commit('selectFriend', res.data.toId)
      let params = {
        sessionId: res.data.id,
        limit: 10,
      }
      dispatch('getConversationHistory', params)
      dispatch('clearUnreadStatus')
    }
    let arr = JSON.parse(JSON.stringify(state.conversations))
    let index = arr.findIndex(v => v.id === res.data.id)
    if (index === -1) {
      let params1 = {
        ...state.chartListPageInfo,
      }
      // 获取并设置会话列表
      const res = await api.getConversationListByPage(params1)
      // 获取并设置会话列表
      if (res.code !== '200') {
        return false
      }
      let list = (res.data && res.data.records) || []
      list = list.map((item) => new ConversationObj(item))
      commit('setConversations', list)
    } else {
      let obj = JSON.parse(JSON.stringify(arr[index]))
      arr.splice(index, 1)
      arr.unshift(obj)
      commit('setConversations', arr)
    }
  },
  creatServerChat: async ({ state, commit, dispatch }) => {
    let params = {
      // entrance: '1',
      entrance: '4',
      origin: '',
      targetId: '0',
      userType: 999,
    }
    let res = await api.imSession(params)
    if (res.code === '200') {
      commit('setSelectTarget', res.data.id)
      commit('setFirstSelectTarget', res.data.id) // 设置第一次 selectTarget
      commit('selectFriend', res.data.toId)
      let params = {
        sessionId: res.data.id,
        limit: 10,
      }
      dispatch('getConversationHistory', params)
      dispatch('clearUnreadStatus')
      if (state.selectTarget) {
        let tatgetList = state.conversations.filter(v => v.id === state.selectTarget)
        if (tatgetList.length) {
          console.log(state.selectTarget)
          commit('setSelectTargetStatus', tatgetList[0].imStatus)
        }
      }
    }
  },
  async getReadTimeStamp({ commit }, value) {
    let params = { sessionId: value }
    let res = await api.getReadTimeStemp(params)
    if (res.success) {
      commit('setReadTimestamp', res.data.toReadTime)
    }
  },
  async getConversationListByPage({ state, commit }, loadMore) {
    if (!state.chatListNomore) {
      let pageInfo = JSON.parse(JSON.stringify(state.chartListPageInfo))
      loadMore && pageInfo.page++
      let params = {
        ...pageInfo,
        name: state.searchText,
      }
      let res = await api.getConversationListByPage(params)
      if (res.code === '200') {
        commit('setChatListPageInfo', pageInfo)
        let list = res.data.records || []
        // 数据格式转换
        list = list.map((item) => new ConversationObj(item))
        if (loadMore) {
          list = state.conversations.concat(list)
        }
        console.log(list.length, res.data.total)
        commit('setChatListNomore', list.length >= res.data.total)
        commit('setConversations', list)
      }
    }
  },
  setUserInfo: async ({ state, commit }) => {
    let res
    if (localStorage.getItem('isManageSign')) {
      let { sessionId } = wj.getQuery(location.href)
      let data = await api.managerGetUserInfo({ sessionId: sessionId })
      res = data.data
    } else {
      res = await api.getUserInfo()
      sessionStorage.setItem('from-id', res.imId)
    }
    let data = {
      userId: res.userId,
      name: res.displayName,
      img: res.headIcon || wj.getAvatar(),
      imId: res.imId,
      systemCode: res.systemCode,
      userType: res.userType,
      imStatus: res.imStatus,
    }
    commit('setUserInfo', data)
  },
  updateReadMessage: ({ state, commit }, value) => {
    let conversationsList = JSON.parse(JSON.stringify(state.conversations))
    let sessionId = sessionStorage.getItem('session-id')
    conversationsList.map(item => {
      if (item.conversationInfo.target === value.payload.sessionId) {
        let formId = sessionStorage.getItem('from-id')
        if (+value.payload.from !== +formId) {
          // 只有当对方发送已读消息时间时接收对方的已读时间更新
          item.conversationInfo.readTimestamp = value.payload.readTime
          // 只有再当前会话才更新session中的tamp
          if (sessionId === value.payload.sessionId) {
            commit('setReadTimestamp', value.payload.readTime)
          }
        } else {
          // 已放不同端接收
          item.conversationInfo.unreadCount.unread = 0
        }
      }
    })
    setTimeout(() => {
      commit('setConversations', conversationsList)
    }, 100)
  },
  updateTime: ({ state, commit }, value) => {
    let conversationsList = JSON.parse(JSON.stringify(state.conversations))
    conversationsList.map(item => {
      if (item.conversationInfo.target === value.payload.sessionId) {
        item.conversationInfo.timestamp = value.payload.receivedTime
      }
    })
    let messageList = JSON.parse(JSON.stringify(state.messages))
    messageList.map(item => {
      if (item.target === value.payload.sessionId) {
        item.protoMessages[item.protoMessages.length - 1].timestamp = value.payload.receivedTime
      }
    })
    // console.log(conversationsList, messageList, '收到消息更新时间')
    commit('setConversations', conversationsList)
    commit('setMessages', messageList)
  },
  changeSelfPerson: ({ state, commit }, value) => {
    let conversationsList = JSON.parse(JSON.stringify(state.conversations))
    console.log(value, 'value')
    conversationsList.map((conv, index) => {
      if (conv.conversationInfo.target === value.sessionId) {
        conv.conversationInfo.toId = value.content.text.from
        conv.name = value.content.text.fromName
        conv.markName = value.content.text.fromMarkName
        // 如果是当前会话  更新缓存中的friend-id
        if (value.sessionId === state.selectTarget) {
          commit('selectFriend', value.content.text.from)
        }
        // 转交的时候更新聊天对话框上的客服名字，和虚竹约定了 targetStaffName、zhikeUserPhone 有值就是转交的
        if (value.content && value.content.text && value.content.text.targetStaffName) {
          let oldMarkName = value.content.text.targetStaffName
          if (state.isServiceConversation) {
            oldMarkName = '平台客服'
          }
          commit('setOldMarkName', oldMarkName)
        }
      }
    })
    commit('setConversations', conversationsList)
  },
  // 移动会话到前面
  toFront({ state, commit }) {
    // 移动会话到前面
    let sessionId = sessionStorage.getItem('session-id')
    let conversationsList = JSON.parse(JSON.stringify(state.conversations))
    let index = 0
    let topIndex = 0 // 置顶的index
    let hasTop = false
    conversationsList.map((conv, idx) => {
      if (conv.conversationInfo.target === sessionId) {
        index = idx
        hasTop = conv.isTop
      }
      conv.isTop && (topIndex = idx + 1)
    })
    let conversation = conversationsList.splice(index, 1)[0]
    conversationsList.splice(hasTop ? 0 : topIndex, 0, conversation)
    commit('setConversations', conversationsList)
  },
  // 置顶
  conversationToTop({ state, dispatch, commit }, { target, type }) {
    // type  0取消置顶 1 置顶
    if (!type) {
      dispatch('getConversations')
      return
    }
    let list = JSON.parse(JSON.stringify(state.conversations))
    let index
    list.map((conv, idx) => {
      if (conv.conversationInfo.target === target) {
        conv.isTop = type
        index = idx
      }
    })
    let conversation = list.splice(index, 1)[0]
    list.splice(0, 0, conversation)
    commit('setConversations', list)
  },
  changeInputing({ state, commit }, payload) {
    let sessionId = payload.content.text.target
    if (state.selectTarget === sessionId) {
      commit('setInputing', true)
      if (inputingTimer) {
        clearTimeout(inputingTimer)
        inputingTimer = null
      }
      inputingTimer = setTimeout(() => {
        commit('setInputing', false)
        clearTimeout(inputingTimer)
        inputingTimer = null
      }, 1800)
    }
  },
  setInputing: ({ commit }, value) => {
    if (!value) {
      clearTimeout(inputingTimer)
      inputingTimer = null
    }
    commit('setInputing', value)
  },
}
