/**
 * 本地缓存，包括如下基本信息
 * 消息缓存
 * 消息当前序列号，用于消息拉取
 * 朋友列表信息
 * 群组信息
 */
export default class LocalStore {
  static saveConverSations(value) {
    localStorage.setItem('coversations', JSON.stringify(value))
  }

  static getConversations() {
    let vaule = localStorage.getItem('coversations')
    return JSON.parse(vaule)
  }

  static getLastMessageSeq() {
    return localStorage.getItem('last_message_seq')
  }

  // 设置上传 token，根据不同 media 类型存储
  static setUploadToken(key, token) {
    localStorage.setItem(key, token)
  }

  static getImageUploadToken() {
    return localStorage.getItem('http://image.comsince.cn/')
  }

  // messageSeq 为 string 类型
  static setLastMessageSeq(messageSeq) {
    localStorage.setItem('last_message_seq', messageSeq)
  }

  static saveMessages(value) {
    localStorage.setItem('messages', JSON.stringify(value))
  }

  static getMessages() {
    let value = localStorage.getItem('messages')
    return JSON.parse(value)
  }

  static saveUserInfoList(value) {
    localStorage.setItem('user_infos_list', JSON.stringify(value))
  }

  static getUserInfoList() {
    let value = localStorage.getItem('user_infos_list')
    return JSON.parse(value)
  }

  // 记录消息发送条数
  static updateSendMessageCount() {
    let value = localStorage.getItem('send_message_count')
    if (value) {
      value = parseInt(value) + 1
    } else {
      value = 1
    }
    localStorage.setItem('send_message_count', value)
  }

  static getSendMessageCount() {
    let value = localStorage.getItem('send_message_count')
    if (!value) {
      value = 0
    }
    return value
  }

  static resetSendMessageCount() {
    localStorage.setItem('send_message_count', 0)
  }

  static getUserId() {
    return localStorage.getItem('user-id')
  }

  static setSelectTarget(value) {
    sessionStorage.setItem('session-id', value)
  }

  static getSelectTarget() {
    return sessionStorage.getItem('session-id')
  }

  static setFriendRequestVersion(version) {
    localStorage.setItem('friend_request_version', version)
  }

  static getFriendRequestVersion() {
    let version = localStorage.getItem('friend_request_version')
    if (!version) {
      version = 0
    }
    return version
  }

  static saveMessageId(messageId) {
    localStorage.setItem('message_id', messageId)
  }

  static getMessageId() {
    let messageId = localStorage.getItem('message_id')
    if (!messageId) {
      messageId = 0
    }
    return messageId
  }

  static setSelectFriendId(id) {
    sessionStorage.setItem('friend-id', id)
  }

  static getSelectFriendId() {
    return sessionStorage.getItem('friend-id')
  }

  static setSeqIdList(value) {
    let val = JSON.stringify(value)
    localStorage.setItem('setSeqIdList', val)
  }

  static getSeqIdList() {
    return JSON.parse(localStorage.getItem('setSeqIdList'))
  }

  static setUserInfo(value) {
    localStorage.setItem('userInfo', JSON.stringify(value))
  }

  static getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'))
  }

  static clearLocalStore() {
    localStorage.setItem('coversations', '')
    localStorage.setItem('last_message_seq', '')
    localStorage.setItem('messages', '')
    localStorage.setItem('send_message_count', 0)
    sessionStorage.setItem('session-id', '')
    localStorage.setItem('friend_request_version', 0)
    localStorage.setItem('user-id', '')
    localStorage.setItem('message_id', 0)
    sessionStorage.setItem('friend-id', 0)
    localStorage.setItem('setSeqIdList', '')
    localStorage.setItem('setUserInfo', '')
  }
}
