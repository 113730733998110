import MessageStatus from './messageStatus'
import ConversationType from '../model/conversationType'
import ProtoMessageContent from './protomessageContent'
// import LocalStore from '@/websocket/store/localstore'

/**
 * 聊天信息 content，在发送的时候转换为 json 消息体发送
 */
export default class ProtoMessage {
  conversationType
  target
  line
  from = ''
  content = {}
  messageId = '0'
  direction = 0
  status = 0
  messageUid = '0'
  timestamp = 0
  tos = ''

  static toProtoMessage(obj) {
    // let currentUserId = LocalStore.getUserId()
    let currentUserId = sessionStorage.getItem('from-id')
    let protoMessage = new ProtoMessage()
    if (+obj.from === +currentUserId) {
      protoMessage.direction = 0
      protoMessage.status = MessageStatus.Sent
    } else {
      protoMessage.direction = 1
      protoMessage.status = MessageStatus.Unread
    }
    protoMessage.tos = obj.tos
    protoMessage.messageId = obj.messageId
    protoMessage.messageUid = obj.messageId
    protoMessage.timestamp = obj.timestamp
    protoMessage.conversationType = obj.conversationType
    protoMessage.target = obj.target
    protoMessage.from = obj.from
    if (protoMessage.conversationType === ConversationType.Single) {
      if (obj.from !== currentUserId) {
        protoMessage.target = obj.from
        protoMessage.from = obj.from
      } else {
        protoMessage.target = obj.target
        protoMessage.from = obj.from
      }
    }
    protoMessage.line = obj.line
    protoMessage.content = ProtoMessageContent.toProtoMessageContent(obj.content)
    return protoMessage
  }

  /***
   * 转为即将发送的 protomessage
   */
  static convertToProtoMessage(message) {
    let protoMessage = new ProtoMessage()
    let type = localStorage.getItem('user-type')
    protoMessage.conversationType = message.conversation.type
    protoMessage.target = message.conversation.target
    protoMessage.line = message.conversation.line
    protoMessage.from = message.from
    protoMessage.tos = message.tos
    protoMessage.direction = message.direction
    protoMessage.status = message.status
    protoMessage.messageId = message.messageId
    protoMessage.messageUid = message.messageUid
    protoMessage.timestamp = message.timestamp
    // 1:直客->服务商 2: 服务商->运力商
    protoMessage.type = type === '3' ? 2 : 1
    let payload = message.content.encode()
    protoMessage.content = ProtoMessageContent.toProtoMessageContent(payload)
    protoMessage.clientType = '5'
    protoMessage.clientStr = 'PC端'
    return protoMessage
  }
}
