import router from '@/router'
import Notify from '@wcjiang/notify'
import * as wj from '@/common/wjtools'
import VoipClient from '@/webrtc/voipclient'
// import GroupCallClient from '@/webrtc/groupCallClient'
import VueWebSocket from '@/websocket'
import ChatManager from '@/websocket/chatManager'
import LocalStore from '@/websocket/store/localstore'
import Message from '@/websocket/message/message'
import ProtoMessage from '@/websocket/message/protomessage'
import MessageConfig from '@/websocket/message/messageConfig'
import MessageStatus from '@/websocket/message/messageStatus'
import RecallMessageNotification from '@/websocket/message/notification/recallMessageNotification'
import ProtoMessageContent from '@/websocket/message/protomessageContent'
import ConversationType from '@/websocket/model/conversationType'
import StateConversationInfo from '@/websocket/model/stateConversationInfo'
import ProtoConversationInfo from '@/websocket/model/protoConversationInfo'
import UnreadCount from '@/websocket/model/unReadCount'
import StateSelectChateMessage from '@/websocket/model/stateSelectChatMessage'
// import Logger from '@/websocket/utils/logger'
import {
  CONVERSATION_MAX_MESSAGE_SIZE,
} from '@/config'

export default {
  // 初始化状态数据
  initData(state) {
    // 消息提醒-通知
    state.notify = new Notify({
      effect: 'flash',
      interval: 500,
      onclick() {
        state.notify.close()
      },
      audio: {
        file: [require('@/assets/audio/notify.mp3')],
      },
    })

    // 消息提醒-来电
    state.inCommingNotify = new Notify({
      audio: {
        file: [require('@/assets/audio/incoming-call.mp3')],
      },
    })

    // 消息提醒-发送
    state.outGoingNotify = new Notify({
      audio: {
        file: [require('@/assets/audio/outgoing-call.mp3')],
      },
    })

    // voip client
    try {
      state.vueSocket = new VueWebSocket()
      state.voipClient = new VoipClient(this)
      // state.groupCallClient = new GroupCallClient(this)
    } catch (r) {
      console.warn('初始化状态数据出错：', r)
    }

    // // 初始化时先从本地获取
    let conversations = LocalStore.getConversations()
    if (conversations) {
      state.conversations = conversations
    }

    let messages = LocalStore.getMessages()
    if (messages) {
      state.messages = messages
    }
    state.selectTarget = LocalStore.getSelectTarget()
    let seqIdList = LocalStore.getSeqIdList()
    if (seqIdList) {
      state.seqIdList = seqIdList
    }

    let userInfoList = LocalStore.getUserInfoList()
    if (userInfoList) {
      state.userInfoList = userInfoList
    }

    let readTimestamp = sessionStorage.getItem('readTimestamp')
    if (readTimestamp) {
      state.readTimestamp = readTimestamp
    }
  },

  initServiceData(state) {
    try {
      state.vueSocket = new VueWebSocket()
      state.voipClient = new VoipClient(this)
      // state.groupCallClient = new GroupCallClient(this)
    } catch (r) {
      console.warn('初始化状态数据出错：', r)
    }
  },

  // 更新登录态
  upLoginState(state, { token = '', userType = '' }) {
    state.token = token
    state.userType = userType
  },
  setInitHref(state, {
    shopIdName = '',
    shopId = '',
    isManageSign = '',
    supplierId = '',
    userCode = '',
    toType = '',
    imOrderId = '',
    channelId = '',
    channelLibId = '',
    cityId = '',
    countryCode = '',
    warehouseAddress = '',
    warehouseType = '',
    totalWeight = '',
    totalVolume = '',
    storeId = '',
    storeNum = '',
    origin = '',
    imOrderType = '',
  }) {
    state.shopId = shopId
    state.isManageSign = isManageSign
    state.supplierId = supplierId
    state.userCode = userCode
    state.toType = toType
    state.imOrderId = imOrderId
    state.channelId = channelId
    state.channelLibId = channelLibId
    state.cityId = cityId
    state.countryCode = countryCode
    state.warehouseAddress = warehouseAddress
    state.warehouseType = warehouseType
    state.totalWeight = totalWeight
    state.totalVolume = totalVolume
    state.shopIdName = shopIdName
    state.storeId = storeId
    state.storeNum = storeNum
    state.origin = origin
    state.imOrderType = imOrderType
  },
  // 设置渠道 ID
  setShopId(state, value) {
    state.shopId = value
  },
  setSupplierId(state, value) {
    state.supplierId = value
  },
  setUserCode(state, value) {
    state.userCode = value
  },
  setToType(state, value) {
    state.toType = value
  },
  // 设置搜索文本
  search(state, value) {
    state.searchText = value
  },

  // 切换全屏模式
  toggleFullScreenMode(state) {
    state.fullScreenMode = !state.fullScreenMode
  },

  // 设置应用高度
  setAppHeight(state, height) {
    state.appHeight = height
  },

  // 是否显示二维码浮窗
  setQrcodeVisible(state, value) {
    state.qrcodeVisible = value
  },

  // 选择会话
  selectConversation(state, convInfo) {
    state.selectTarget = convInfo.target
    let o = state.seqIdList.find(v => state.selectTarget === v.selectTarget)
    if (o) {
      this.commit('setSeqId', o.seqId)
    }
    this.commit('setReadTimestamp', convInfo.readTimestamp)
    this.commit('selectFriend', convInfo.toId)
    // this.dispatch('clearUnreadStatus')
  },
  // 清除未读数
  clearUnreadStatus(state) {
    let conv = state.conversations.find(conv => conv.conversationInfo.target === state.selectTarget)
    if (conv && conv.conversationInfo.unreadCount) {
      conv.conversationInfo.unreadCount.unread = 0
    }
  },

  // 得知用户当前选择的是哪个好友
  selectFriend(state, value) {
    state.selectFriendId = value
    if (value === 0) {
      if (!state.friendRequests.length) {
        state.vueSocket.getFriendRequest(LocalStore.getFriendRequestVersion())
      } else {
        state.newFriendRequestCount = 0
      }
    }
  },

  // 更新朋友列表
  updateFriendList(state, value) {
    if (!state.friendlist.length) {
      state.friendlist.push({
        id: 0,
        wxid: 'new', // 微信号
        initial: '新的朋友', // 姓名首字母
        img: require('@/assets/chat/icon-newfriend.jpg'), // 头像
        signature: '', // 个性签名
        nickname: '新的朋友', // 昵称
        sex: 0, // 性别 1为男，0为女
        remark: '新的朋友', // 备注
        area: '', // 地区
      })
    }

    for (let i in value) {
      let currentUser = value[i]
      if (currentUser.wxid !== state.userId) {
        let friendUid = state.friendIds.find(friendUid => friendUid === currentUser.wxid)
        if (friendUid) {
          let friendData = state.friendDatas.find(friend => friend.friendUid === friendUid)
          if (friendData && friendData.alias && friendData.alias !== '') {
            currentUser.remark = friendData.alias
          }
          let isExist = false
          for (let friend of state.friendlist) {
            if (friend.wxid === currentUser.wxid) {
              isExist = true
              friend.nickname = currentUser.nickname
              friend.img = currentUser.img
              friend.remark = currentUser.remark
            }
          }
          if (!isExist) {
            currentUser.id = state.friendlist.length
            state.friendlist.push(currentUser)
          }
        }
      }
    }

    // 更新会话信息
    for (let stateConversationInfo of state.conversations) {
      let friend = state.friendlist.find(friend => friend.wxid === stateConversationInfo.conversationInfo.target)
      if (friend) {
        stateConversationInfo.name = friend.remark ? friend.remark : friend.nickname
        stateConversationInfo.img = friend.img
      }
    }

    // 更新消息列表信息
    for (let stateChatMessage of state.messages) {
      let friend = state.friendlist.find(friend => friend.wxid === stateChatMessage.target)
      if (friend) {
        stateChatMessage.name = friend.remark ? friend.remark : friend.nickname
      }
    }
  },

  // 更新会话信息
  updateConversationBrief(state) {
    for (let stateConversationInfo of state.conversations) {
      let friend = state.friendlist.find(friend => friend.wxid === stateConversationInfo.conversationInfo.target)
      if (friend) {
        stateConversationInfo.name = friend.remark ? friend.remark : friend.nickname
        stateConversationInfo.img = friend.img
      } else {
        let user = state.userInfoList.find(user => user.uid === stateConversationInfo.conversationInfo.target)
        if (user) {
          stateConversationInfo.name = user.displayName
          stateConversationInfo.img = user.portrait
        }
      }
    }
  },

  // 更新消息列表信息
  updateMessageBrief(state) {
    for (let stateChatMessage of state.messages) {
      let friend = state.friendlist.find(friend => friend.wxid === stateChatMessage.target)
      if (friend) {
        stateChatMessage.name = friend.remark ? friend.remark : friend.nickname
      } else {
        let user = state.userInfoList.find(user => user.uid === stateChatMessage.target)
        if (user) {
          stateChatMessage.name = user.displayName
        }
      }
    }
  },

  updateUserInfos(state, userInfos) {
    for (let currentUserInfo of userInfos) {
      if (currentUserInfo.uid === state.userId) {
        state.user.img = currentUserInfo.portrait
        state.user.name = currentUserInfo.displayName
      }
      let isExist = false
      let deleteIndex = 0
      for (let index in state.userInfoList) {
        let userInfo = state.userInfoList[index]
        if (userInfo.uid === currentUserInfo.uid) {
          isExist = true
          deleteIndex = index
          break
        }
      }
      if (isExist) {
        state.userInfoList.splice(deleteIndex, 1, currentUserInfo)
      } else {
        state.userInfoList.push(currentUserInfo)
      }
    }
    this.commit('updateConversationBrief')
    this.commit('updateMessageBrief')
  },

  updateGroupInfos(state, groupInfos) {
    for (let currentGroupInfo of groupInfos) {
      let isExist = false
      let deleteIndex = 0
      for (let index in state.groupInfoList) {
        let groupInfo = state.groupInfoList[index]
        if (groupInfo.target === currentGroupInfo.target) {
          isExist = true
          deleteIndex = index
          break
        }
      }
      if (isExist) {
        state.groupInfoList.splice(deleteIndex, 1, currentGroupInfo)
      } else {
        state.groupInfoList.push(currentGroupInfo)
      }
    }
    this.commit('updateConversationIntro', groupInfos)
  },

  getGroupInfo(state, target) {
    state.vueSocket.getGroupInfo(target, false)
  },

  getGroupMember(state, groupId) {
    state.vueSocket.getGroupMember(groupId, true)
  },

  quitGroup(state, groupId) {
    state.vueSocket.quitGroup(groupId)
  },

  deleteConversation(state, groupId) {
    // 为防止再次收到消息，退出群组的发起人不应该在接收任何退出群组消息，防止再次产生会话
    let index = -1
    for (let i = 0; i < state.conversations.length; i++) {
      if (state.conversations[i].conversationInfo.target === groupId) {
        index = i
        break
      }
    }

    if (index !== -1) {
      state.conversations.splice(index, 1)
    }

    // 重新选择新的会话
    if (state.conversations.length > 0) {
      state.selectTarget = state.conversations[0].conversationInfo.target
    }
  },

  updateTempGroupMember(state, groupMembers) {
    state.tempGroupMembers = groupMembers
  },

  // 发送信息
  sendMessage(state, sendMessage) {
    state.isLoadRemoteMessage = false
    let message = Message.toMessage(state, sendMessage)
    let protoMessage = ProtoMessage.convertToProtoMessage(message)
    this.commit('preAddProtoMessage', protoMessage)
    // 发送消息到对端
    state.vueSocket.sendMessage(protoMessage)
  },

  // 图片、视频类消息，需要先加入消息，然后上传成功后在更新 message content
  preAddProtoMessage(state, protoMessage) {
    if (!wj.util.isPlainObject(protoMessage)) return
    if (MessageConfig.isDisplayableMessage(protoMessage)) {
      const stateConversation = state.conversations.find(item => item.conversationInfo.target === protoMessage.target)
      console.log('stateConversation', stateConversation)
      if (stateConversation) {
        stateConversation.conversationInfo.lastMessage = protoMessage
        stateConversation.conversationInfo.timestamp = protoMessage.timestamp
      }

      let stateChatMessage = state.messages.find(chatmessage => chatmessage.target === protoMessage.target)
      if (!stateChatMessage) {
        stateChatMessage = new StateSelectChateMessage()
        stateChatMessage.target = protoMessage.target
        let friend = state.friendlist.find(friend => friend.wxid === protoMessage.target)
        if (friend) {
          stateChatMessage.name = friend.nickname
        }
        stateChatMessage.protoMessages.push(protoMessage)
        state.messages.push(stateChatMessage)
      } else {
        // 限制单个会话最大消息存储总数
        if (stateChatMessage.protoMessages.length > CONVERSATION_MAX_MESSAGE_SIZE) {
          stateChatMessage.protoMessages.splice(0, stateChatMessage.protoMessages.length - CONVERSATION_MAX_MESSAGE_SIZE)
        }
        stateChatMessage.protoMessages.push(protoMessage)
      }
    }
  },

  updateSendMessage(state, updateMessage) {
    let stateChatMessage = state.messages.find(stateChatMessage => stateChatMessage.target === state.selectTarget)
    if (stateChatMessage) {
      let protoMessage = stateChatMessage.protoMessages.find(message => message.messageId === updateMessage.messageId)
      if (protoMessage) {
        protoMessage.status = MessageStatus.Sending
        let messagePayload = updateMessage.messageContent.encode()
        protoMessage.content = ProtoMessageContent.toProtoMessageContent(messagePayload)
      }
      state.vueSocket.sendMessage(protoMessage)
    }
    // state.vueSocket.sendMessage(protoMessage) // todo
  },

  // 选择好友后，点击发送信息。判断在聊天列表中是否有该好友，有的话跳到该好友对话。没有的话添加该好友的对话并置顶
  send(state) {
    const result = state.friendlist.find(friend => friend.id === state.selectFriendId)
    const stateConversation = state.conversations.find(conv => conv.conversationInfo.target === result.wxid)

    if (!stateConversation) {
      state.selectTarget = result.wxid
      const protoConversationInfo = new ProtoConversationInfo()
      protoConversationInfo.conversationType = ConversationType.Single
      protoConversationInfo.target = result.wxid
      protoConversationInfo.line = 0
      protoConversationInfo.top = false
      protoConversationInfo.slient = false
      protoConversationInfo.timestamp = new Date().getTime()
      protoConversationInfo.unreadCount = new UnreadCount()
      protoConversationInfo.lastMessage = null

      const newStateConversationInfo = new StateConversationInfo()
      newStateConversationInfo.name = result.remark
      newStateConversationInfo.img = result.img
      newStateConversationInfo.conversationInfo = protoConversationInfo
      state.conversations.unshift(newStateConversationInfo)
    } else {
      state.selectTarget = stateConversation.conversationInfo.target
    }
    router.push({ path: '/' })
  },

  // 更新会话列表  JJ 新增的会话列表数据
  updateConversationInfo(state, protoConversationInfo) {
    let update = false
    let updateStateConverstaionInfo
    let currentConversationInfoIndex
    for (let index in state.conversations) {
      let stateConverstaionInfo = state.conversations[index]
      let is1 = stateConverstaionInfo.conversationInfo.conversationType === protoConversationInfo.conversationType
      let is2 = stateConverstaionInfo.conversationInfo.target === protoConversationInfo.target
      if (is1 && is2) {
        update = true
        currentConversationInfoIndex = index
        stateConverstaionInfo.conversationInfo.lastMessage = protoConversationInfo.lastMessage
        stateConverstaionInfo.conversationInfo.timestamp = protoConversationInfo.lastMessage.timestamp
        updateStateConverstaionInfo = stateConverstaionInfo
        break
      }
    }
    // 新消息会话置顶 TODO 这里进行在置顶之后的处理
    if (update) {
      // state.conversations.splice(currentConversationInfoIndex, 1)
      // state.conversations.unshift(updateStateConverstaionInfo)
      let conversation = state.conversations.splice(currentConversationInfoIndex, 1)[0]
      let hasTop = conversation.isTop
      let topIndex = 0 // 置顶的index
      state.conversations.map((conv, idx) => {
        conv.isTop && (topIndex = idx + 1)
      })
      state.conversations.splice(hasTop ? 0 : topIndex, 0, conversation)
    }
    if (!update) {
      // this.dispatch('getConversations')
      updateStateConverstaionInfo = new StateConversationInfo()
      updateStateConverstaionInfo.conversationInfo = protoConversationInfo
      // 单聊会话
      if (protoConversationInfo.conversationType === ConversationType.Single) {
        let friend = state.friendlist.find(friend => friend.wxid === protoConversationInfo.target)
        if (friend) {
          let name = friend.nickname
          let img = friend.img || wj.getAvatar()
          updateStateConverstaionInfo.name = name
          updateStateConverstaionInfo.img = img
        } else {
          updateStateConverstaionInfo.name = protoConversationInfo.sessionName || protoConversationInfo.fromName || ''
          updateStateConverstaionInfo.img = wj.getAvatar()
          updateStateConverstaionInfo.type = protoConversationInfo.lastMessage.content.type
        }
      } else {
        // 群聊会话
        updateStateConverstaionInfo.name = protoConversationInfo.target
        if (!updateStateConverstaionInfo.img) {
          state.vueSocket.getGroupInfo(protoConversationInfo.target, false)
        }
        updateStateConverstaionInfo.img = wj.getAvatar()
      }

      // 周小黑 2022-10-22
      // target 和 conversationType 不存在时，上面会走群聊那，会直接往 conversations 添加一条脏数据
      // 先在这里判断下，根源在 chat-vip 那边聊天包里的 target 缺失
      protoConversationInfo.target && state.conversations.unshift(updateStateConverstaionInfo)
    }

    // 消息是否属于当前会话
    let isCurrentConversationMessage = state.selectTarget === protoConversationInfo.target
    let visibilityStateVisible = state.visibilityState === 'visible'
    // 只显示接收消息，同一用户不同session，不再通知
    let isShowSendingMessage = protoConversationInfo.lastMessage.direction === 1
    // 更新会话消息未读数
    let is = (!isCurrentConversationMessage || (isCurrentConversationMessage && !visibilityStateVisible))
    if (is && isShowSendingMessage) {
      // 统计消息未读数,注意服务端暂时还没有将透传消息发送过来，原则上这里过来的消息都不是透传消息
      updateStateConverstaionInfo.conversationInfo.unreadCount.unread += 1
      let notifyBody = protoConversationInfo.lastMessage.content.searchableContent
      if (!notifyBody) {
        notifyBody = ProtoMessageContent.typeToContent(protoConversationInfo.lastMessage.content)
      }
      // notify 弹框
      state.notify.player()
      state.notify.notify({
        title: updateStateConverstaionInfo.name, // Set notification title
        body: notifyBody, // Set message content
        icon: updateStateConverstaionInfo.img,
      })
    }
    if (isCurrentConversationMessage && visibilityStateVisible) {
      this.dispatch('clearUnreadStatus')
    }
  },

  // 更新会话简介，主要更新会话的名称与图像
  updateConversationIntro(state, groupInfos) {
    for (let groupInfo of groupInfos) {
      let stateConverstaionInfo = state.conversations.find(stateConverstaionInfo => stateConverstaionInfo.conversationInfo.target === groupInfo.target)
      if (stateConverstaionInfo) {
        stateConverstaionInfo.name = groupInfo.name
        if (groupInfo.portrait) {
          stateConverstaionInfo.img = groupInfo.portrait
        }
      }

      // 更新会话标题
      let stateChatMessage = state.messages.find(stateChatMessage => stateChatMessage.target === groupInfo.target)
      if (stateChatMessage) {
        stateChatMessage.name = groupInfo.name + '(' + groupInfo.memberCount + ')'
      }
    }
  },

  // 获取用户当前会话的历史消息
  addOldMessage(state, protoMessage) {
    state.isLoadRemoteMessage = true
    for (let stateChatMessage of state.messages) {
      if (protoMessage.target === stateChatMessage.target) {
        // let isSameProtoMessage = stateChatMessage.protoMessages.find(message => message.messageId === protoMessage.messageId)
        // if (!isSameProtoMessage) {
        stateChatMessage.protoMessages.unshift(protoMessage)
        // }
      }
    }
  },
  // JJ 收到消息
  addProtoMessage(state, protoMessage) {
    if (protoMessage.target === state.selectTarget) {
      if (protoMessage.direction !== 0) { // 接收方发送消息
        this.commit('setToNewText', '您有新消息')
      }
    }
    state.isLoadRemoteMessage = false
    // 更新用户信息
    if (state.waitUserIds.includes(protoMessage.from)) {
      state.waitUserIds.push(protoMessage.from)
      state.vueSocket.getUserInfos([protoMessage.from])
    }

    let added = false
    let isExistMessage = false
    for (let stateChatMessage of state.messages) {
      // 如果有这个会话
      if (protoMessage.target === stateChatMessage.target) {
        added = true
        let isSameProtoMessage = stateChatMessage.protoMessages.find(message => message.messageId === protoMessage.messageId)
        if (!isSameProtoMessage) {
          stateChatMessage.protoMessages.push(protoMessage)
        } else {
          isExistMessage = true
        }
      }
    }
    // 如果不是message中新的一条消息  而是新的message
    if (!added) {
      let stateChatMessage = new StateSelectChateMessage()
      let friend = state.friendlist.find(friend => friend.wxid === protoMessage.target)
      if (friend) {
        stateChatMessage.name = friend.nickname
      }
      stateChatMessage.target = protoMessage.target
      stateChatMessage.protoMessages.push(protoMessage)
      state.messages.push(stateChatMessage)
    }
    if (!isExistMessage) {
      let protoConversationInfo = new ProtoConversationInfo()
      protoConversationInfo.conversationType = protoMessage.conversationType
      protoConversationInfo.target = protoMessage.target
      protoConversationInfo.line = 0
      protoConversationInfo.top = false
      protoConversationInfo.slient = false
      protoConversationInfo.timestamp = protoMessage.timestamp
      protoConversationInfo.lastMessage = protoMessage
      protoConversationInfo.unreadCount = new UnreadCount()
      protoConversationInfo.fromName = protoMessage.fromName
      protoConversationInfo.toId = protoMessage.toId
      if (protoMessage.type === 999) {
        protoConversationInfo.sessionName = protoMessage.sessionName
      }
      this.commit('updateConversationInfo', protoConversationInfo)
    }
  },

  updateProtoMessageUid(state, updateMessage) {
    let stateChatMessage = state.messages.find(stateChatMessage => stateChatMessage.target === state.selectTarget)
    if (stateChatMessage) {
      let protoMessage = stateChatMessage.protoMessages.find(message => message.messageId === updateMessage.messageId)
      if (protoMessage) {
        protoMessage.messageUid = updateMessage.messageUid
        return
      }
    }

    // 如果切换聊天，需要全局遍历，暂定
    for (let stateChatMessage of state.messages) {
      for (let protoMessage of stateChatMessage.protoMessages) {
        if (protoMessage.messageId === updateMessage.messageId) {
          protoMessage.messageUid = updateMessage.messageUid
        }
      }
    }
  },

  updateMessageStatus(state, updateMessageStatus) {
    let stateChatMessage = state.messages.find(stateChatMessage => stateChatMessage.target === state.selectTarget)
    if (stateChatMessage) {
      let protoMessage = stateChatMessage.protoMessages.find(message => message.messageId === updateMessageStatus.messageId)
      if (protoMessage) {
        protoMessage.status = updateMessageStatus.status
        return
      }
    }
    // 如果切换聊天，需要全局遍历，暂定。转发消息可能出现这个问题
    for (let stateChatMessage of state.messages) {
      for (let protoMessage of stateChatMessage.protoMessages) {
        if (protoMessage.messageId === updateMessageStatus.messageId) {
          protoMessage.status = updateMessageStatus.status
        }
      }
    }
  },

  deleteMessage(state, messageId) {
    let stateChatMessage = state.messages.find(stateChatMessage => stateChatMessage.target === state.selectTarget)
    if (stateChatMessage) {
      let index = -1
      for (let i = 0; i < stateChatMessage.protoMessages.length; i++) {
        let protoMessage = stateChatMessage.protoMessages[i]
        if (protoMessage.messageId === messageId) {
          index = i
        }
      }
      if (index !== -1) {
        stateChatMessage.protoMessages.splice(index, 1)
      }
    }
  },

  updateMessageContent(state, notifyMessage) {
    let found = false
    for (let stateMessages of state.messages) {
      for (let protoMessage of stateMessages.protoMessages) {
        if (protoMessage.messageUid === notifyMessage.messageUid) {
          let recallMessageContent = new RecallMessageNotification(notifyMessage.fromUser, notifyMessage.messageUid)
          protoMessage.content = recallMessageContent.encode()
          found = true
          break
        }
      }
      if (found) {
        break
      }
    }
  },

  logout(state) {
    wj.clearLogin()
    state.selectTarget = ''
    state.vueSocket.sendDisConnectMessage()
    state.vueSocket = null
    state.voipClient = null
    state.conversations = []
    state.messages = []
    state.friendlist = []
    state.friendIds = []
    state.friendDatas = []
    state.selectFriendId = 0
    state.friendRequests = []
    state.waitUserIds = []
    state.userInfoList = []
    state.newFriendRequestCount = 0
    state.groupMemberTracker = 0
    state.showMessageRightMenu = []
    state.emptyMessage = false
    LocalStore.clearLocalStore()
    ChatManager.removeOnReceiveMessageListener()

    // 发送断开消息，清除 session，防止同一个设备切换登录导致的验证失败
    router.push({ path: '/login' })
  },

  changetFirstLogin(state, value) {
    state.firstLogin = value
  },

  getUploadToken(state, value) {
    state.vueSocket.getUploadToken(value)
  },

  visibilityChange(state, value) {
    state.visibilityState = value
  },

  searchUser(state, value) {
    state.vueSocket.searchUser(value)
  },

  updateSearchUser(state, value) {
    state.searchUsers = []
    for (let searchUser of value) {
      let friend = state.friendlist.find(friend => friend.wxid === searchUser.uid)
      if (!friend && searchUser.uid !== state.userId) {
        state.searchUsers.push(searchUser)
      }
    }
  },

  sendFriendAddRequest(state, value) {
    state.vueSocket.sendFriendAddRequest(value)
  },

  updateFriendRequest(state, value) {
    for (let newFriendRequst of value) {
      if (newFriendRequst.status === 0 && new Date().getTime() - newFriendRequst.timestamp > 7 * 24 * 60 * 60 * 1000) {
        continue
      }
      let friendRequest = state.friendRequests.find(friendRequest => friendRequest.from === newFriendRequst.from)
      if (friendRequest) {
        friendRequest.status = newFriendRequst.status
      } else {
        if (newFriendRequst.status === 0) {
          state.newFriendRequestCount += 1
        }
        state.friendRequests.push(newFriendRequst)
      }
    }
  },

  handleFriendRequest(state, value) {
    let friendRequest = state.friendRequests.find(friendRequest => friendRequest.from === value.targetUid)
    friendRequest.status = 1
    state.vueSocket.handleFriendRequest(value)
  },

  updateFriendIds(state, friendList) {
    if (friendList) {
      state.friendDatas = friendList
      let userIds = []
      for (let i in friendList) {
        userIds[i] = friendList[i].friendUid
      }
      state.friendIds = userIds
    }
  },

  modifyMyInfo(state, value) {
    state.vueSocket.modifyMyInfo(value)
  },

  getUserInfos(state, value) {
    state.vueSocket.getUserInfos(value)
  },

  changeEmptyMessageState(state, value) {
    state.emptyMessage = value
  },

  setConversations(state, value) {
    state.conversations = value
  },

  showMessageRightMenuPush(state, value, hidd = true) {
    let menuSetting = state.showMessageRightMenu.find(setting => setting.messageId === value)
    if (menuSetting) {
      menuSetting.show = hidd
    } else {
      state.showMessageRightMenu.push({
        messageId: value,
        show: true,
      })
    }
  },

  currentRightMenuMessage(state, value) {
    state.currentRightMenuMessage = value
  },
  hideSidebar(state, value) {
    state.hideSidebar = value
  },
  setMessages(state, value) {
    state.messages = value
  },
  setSeqId(state, value) {
    let seqId = value.seqId ? value.seqId : value
    let isUpdate = value.isUpdate ? value.isUpdate : false
    state.seqId = seqId
    let is = false
    let seqIdList = [...state.seqIdList]
    let o = seqIdList.find(v => v.selectTarget === state.selectTarget)
    if (o) {
      if (o.seqId > seqId || isUpdate) {
        o.seqId = seqId
        is = true
      }
    } else {
      seqIdList.push({
        seqId: seqId,
        selectTarget: state.selectTarget,
      })
      is = true
    }
    if (is) {
      this.commit('setSeqIdList', seqIdList)
    }
  },
  setSeqIdList(state, value) {
    state.seqIdList = value
  },
  setSelectTarget(state, value) {
    state.selectTarget = value
  },
  setFirstSelectTarget (state, value) {
    if (!state.firstSelectTarget.find(v => v.selectTarget === value)) {
      state.firstSelectTarget.push({ selectTarget: value, channelId: state.channelId })
    }
  },
  setChannelInfoChat (state, value) {
    state.channelInfoChat = value
    state.channelId = value.channelId
    state.shopIdName = state.channelName
  },
  setChannelSelectTarget (state, value) {
    state.channelSelectTarget = value
  },
  setUserInfo(state, value) {
    state.user = value
    LocalStore.setUserInfo(value)
  },
  setReadTimestamp(state, value) {
    state.readTimestamp = value
  },
  setSys(state, value) {
    state.sys = value
  },
  setChatListPageInfo(state, value) {
    state.chartListPageInfo = value
  },
  setChatListNomore(state, value) {
    state.chatListNomore = value
  },
  setRootParams(state, payload) {
    state.rootParams = payload
  },
  setShowNetworkIn(state, payload) {
    state.showNetworkIn = payload
  },
  setShowNetworkOut(state, payload) {
    state.showNetworkOut = payload
  },
  setShowAddEnquiry(state, payload) {
    state.showAddEnquiry = payload
  },
  setShowTransportation(state, payload) {
    state.showTransportation = payload
  },
  setShowEnquiryInfo(state, payload) {
    state.showEnquiryInfo = payload
  },
  setIsAInquiry(state, payload) {
    state.isAInquiry = payload
  },
  setShowLookEnquiry(state, payload) {
    state.showLookEnquiry = payload
  },
  setAddEnquiry (state, { showAddEnquiry = false, isAInquiry = false, showLookEnquiry = false, publishId = '' }) {
    state.showAddEnquiry = showAddEnquiry
    state.isAInquiry = isAInquiry
    state.showLookEnquiry = showLookEnquiry
    state.publishId = publishId
  },
  setEnquiryInfo(state, payload) {
    state.enquiryInfo = payload
  },
  setShowChannelMessage(state, payload) {
    state.showChannelMessage = payload
  },
  setPageInfo (state, payload) {
    state.pageInfo = payload
  },
  setShowCopy (state, payload) {
    state.showCopy = payload
  },
  setCopyInfo (state, payload) {
    state.copyInfo = payload
  },
  setToNewText(state, payload) {
    state.toNewText = payload
  },
  setShowConnectTips(state, payload) {
    state.showConnectTips = payload
  },
  setInputing(state, value) {
    state.inputing = value
  },
  setSelectTargetStatus(state, value) {
    state.imStatus = value
  },
  setConversationType(state, value) {
    state.conversationType = value
    state.isServiceConversation = value === 'service'
  },
  setServiceConversation(state, value) {
    state.serviceConversation = value
  },
  setOldMarkName(state, value) {
    state.oldMarkName = value
  },
}
