import AbstractMessageHandler from './abstractMessageHandler'
import { PUB_ACK } from '@/config'
/**
 * 关于promise返回说明
 * 1.对于操作型消息，一般只需要返回成功与失败即可
 * 2.对于结果型消息，一般判断是否为空
 */
export default class UpdateTimeHandler extends AbstractMessageHandler {
  match(proto) {
    // return proto.signal == PUB_ACK && proto.subSignal == MP
    return proto.signal === PUB_ACK
  }

  processMessage(proto) {
    if (proto.payload.messageType === 1000) return // 正在输入的不更新时间
    this.vueWebsocket.sendAction('updateTime', proto)
    // 修改本地缓存的逻辑 store中的conversation
  }
}
