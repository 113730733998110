import Vue from 'vue'
import './styles/main.styl'
import stat from 'vue-stat'
import App from './App'
import store from './store'
import router from './router'
import filters from './filters'
import * as config from './config'
import * as wj from './common/wjtools'
import * as alioss from './common/alioss'
import * as api from './api'
import './common/element'
import istat from './common/istat'
// import './common/sentry'

Vue.config.productionTip = false // 关闭开发环境提示
Vue.prototype.$assets = config.assets
Vue.prototype.$config = config
Vue.prototype.$api = api
Vue.prototype.$wj = wj
Vue.prototype.$util = wj.util
Vue.prototype.$alioss = alioss
filters(Vue) // 注册全局过滤器

// 初始化百度统计
Vue.prototype.$stat = stat // 统计上报方法
Vue.use(stat, {
  debug: !config.isOnline,
  siteId: config.statid,
  autoPageview: false,
})

// 初始化 istat 统计
Vue.use(istat, {
  debug: !config.isOnline,
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
