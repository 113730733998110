// WebSocket 封装 by steven 2021-01-18
import {
  VERSION,
  PUBLISH,
  PING,
  FP,
  // UPUI,
  MP,
  MS,
  DISCONNECT,
  GPGI,
  GQNUT,
  US,
  FAR,
  FRP,
  FHR,
  MMI,
  GPGM,
  GC,
  GQ,
  PUB_ACK,
  ERROR_CODE,
  MR,
  GAM,
  GMI,
  GKM,
  GD,
  GMURL,
  FALS,
  LRM,
  CONNECT,
  HEART_BEAT_INTERVAL,
  RECONNECT_INTERVAL,
  BINTRAY_TYPE,
  socketUrl,
} from '@/config'
import vuexStore from '@/store'
import LocalStore from './store/localstore'
import WebSocketProtoMessage from './message/webSocketProtoMessage'
import PromiseResolve from './future/promiseResolve'
import FutureResult from './future/futureResult'
import GroupInfo from './model/groupInfo'
import GroupType from './model/groupType'
import GroupMember from './model/groupMember'
import GroupMemberType from './model/groupMemberType'
import ConnectSuccessHandler from './handler/connectSuccessHandler'
import ConnectFailHandler from './handler/connectFailHandler'
import ReceiveMessageHandler from './handler/receiveMessageHandler'
import NotifyMessageHandler from './handler/notifyMessageHandler'
import SendMessageHandler from './handler/sendMessageHandler'
import HadReadHandler from './handler/hadReadHandler'
import UpdateTimeHandler from './handler/updateTimeHandler'
import * as api from '@/api'
import istat from '@/common/istat'

export default class VueWebSocket {
  token = localStorage.getItem('token')
  userType = localStorage.getItem('user-type')
  binaryType = BINTRAY_TYPE
  heartbeatTimeout = HEART_BEAT_INTERVAL
  reconnectInterval = RECONNECT_INTERVAL
  handlerList = []
  userDisconnect = false
  isconnected = false
  isOneConnect = false
  failSendData = null
  resolvePromiseMap = new Map()

  // 构造函数
  constructor(url = socketUrl) {
    let params = localStorage.getItem('system-type') === 'HHY' ? '&sys=HHY' : ''
    this.url = `${url}?token=${this.token}&userType=${this.userType}${params}`
    this.initHandlerList()
    this.connect(true)
  }

  // 创建连接
  async connect(isReconncect) {
    try {
      let res = await api.getReconnectTime()
      if (res.code === '200' && res.data.interval) {
        this.heartbeatTimeout = res.data.interval * 1000
      }
    } catch (error) {
      console.log(error)
    }

    // 初始化一个 WebSocket 对象
    let ws = this.ws = new WebSocket(this.url)
    // console.log('创建连接：', this.url)
    ws.binaryType = this.binaryType

    // 连接成功回调
    ws.onopen = e => {
      this.isconnected = true
      this.setLastActionTime()
      this.pingIntervalId && clearTimeout(this.pingIntervalId)
      this.userDisconnect = false
      this.sendConnectMessage() // 发送连接信令
      this.ping()
      istat.report('连接成功')
      // sentryReport('连接成功', { name: 'WS 状态数据', ...e })
    }

    // 接收服务端数据后回调
    ws.onmessage = e => {
      // console.log('接收数据：[' + e.data + ']')
      let data = JSON.parse(e.data)
      if (data.signal === 'PONG') { // 心跳
        return false
      }
      this.processMessage(data)
      this.setLastActionTime()
    }

    // 断开连接后回调
    ws.onclose = e => {
      this.isconnected = false
      this.ws.close()
      clearTimeout(this.pingIntervalId)
      if (!this.userDisconnect) {
        // sentryReport('连接意外断开', { name: 'WS 状态数据', ...e })
        istat.report('连接断开')
        // vuexStore.commit('setShowConnectTips', true) // 打开重连提示弹窗
        this.reconnect()
      }
    }

    // 通信出错回调
    ws.onerror = e => {
      // console.log(`通信出错：[${e}]`)
      // sentryReport('通信出错', { name: 'WS 状态数据', ...e })
    }
  }

  // 重新连接
  reconnect() {
    setTimeout(() => {
      this.connect(true)
    }, this.reconnectInterval)
  }

  // 发送心跳
  ping() {
    this.pingIntervalId = setTimeout(() => {
      let wsm = new WebSocketProtoMessage(PING)
      this.send(wsm.toJson())
      this.ping()
    }, this.heartbeatTimeout)
  }

  // 发送数据
  send(data) {
    if (!this.isconnected) {
      return
    }
    this.ws.send(data)

    // 数据异常时上报
    // sentryReport('信号数据异常', data)
  }

  // 发送连接信号
  sendConnectMessage() {
    let wsm = new WebSocketProtoMessage()
    wsm.setSignal(CONNECT)
    wsm.content = {
      token: this.token,
      userType: this.userType,
    }
    this.send(wsm.toJson())

    // 重发失败消息
    if (this.isOneConnect) {
      this.send(this.failSendData)
      setTimeout(() => {
        this.isOneConnect = false
        this.failSendData = null
      }, 0)
    }
  }

  // 发送断开信号
  sendDisConnectMessage() {
    let wsm = new WebSocketProtoMessage()
    wsm.setSignal(DISCONNECT)
    // console.log('发送断开信号', wsm.toJson())
    this.send(wsm.toJson())
    this.userDisconnect = true
  }

  /**
   * 发布消息
   * @param {子信令} subsignal
   * @param {消息体内容} content
   */
  sendPublishMessage(subsignal, content, protoMessageId = 0) {
    let wsm = new WebSocketProtoMessage()
    let messageId = this.getMessageId()
    wsm.setSignal(PUBLISH)
    wsm.setSubSignal(subsignal)
    wsm.setContent(content)
    wsm.setMessageId(messageId)
    this.send(wsm.toJson())

    // TODO
    return new Promise(resolve => {
      let timeoutId = setTimeout(() => {
        if (subsignal === MS) { // 文本消息 自定义子信令
          let fn = () => {
            let failMessage = new WebSocketProtoMessage()
            failMessage.setSignal(PUB_ACK)
            failMessage.setSubSignal('fail') // 自定义超时子信令
            failMessage.setMessageId(messageId)
            failMessage.setContent('')
            this.processMessage(JSON.parse(failMessage.toJson()))
          }
          if (this.isOneConnect) {
            fn()
          } else {
            this.isOneConnect = true
            // 失败时重连(执行中断重连)
            this.isconnected = false
            this.ws.close()
            clearTimeout(this.pingIntervalId)
            if (!this.userDisconnect) {
              this.failSendData = wsm.toJson()
              this.connect(true)
              setTimeout(() => {
                fn()
              }, 5000)
            }
          }
        } else {
          resolve(new FutureResult(ERROR_CODE, ''))
        }
      }, 5000)
      let resolvePromise = new PromiseResolve(resolve, timeoutId)
      resolvePromise.protoMessageId = protoMessageId
      this.resolvePromiseMap.set(messageId, resolvePromise)
    })
  }

  // 发送消息
  sendMessage(protoMessage) {
    this.sendPublishMessage(MS, protoMessage, protoMessage.messageId)
  }

  // 获取并刷新消息ID
  getMessageId() {
    // let messageId = LocalStore.getMessageId() || 0
    // LocalStore.saveMessageId(++messageId)
    let messageId = Date.now().toString() + Math.round(Math.random() * 1000000)
    return messageId
  }

  // 初始化消息回调列表
  initHandlerList() {
    this.handlerList.push(new ConnectSuccessHandler(this)) // 连接成功
    this.handlerList.push(new ConnectFailHandler(this)) // 连接失败
    this.handlerList.push(new ReceiveMessageHandler(this)) // 接收对方发送的消息
    this.handlerList.push(new NotifyMessageHandler(this)) // 消息通知
    this.handlerList.push(new SendMessageHandler(this)) // 发送消息并确认消息
    this.handlerList.push(new HadReadHandler(this)) // 消息已读
    this.handlerList.push(new UpdateTimeHandler(this)) // 确认消息更新时间
  }

  // 解析消息
  processMessage(data) {
    // let msgObj = JSON.parse(data)
    this.handlerList.forEach(item => {
      if (item.match(data)) {
        item.processMessage(data)
      }
    })
  }

  // 分发 vuex action
  sendAction(type, data) {
    vuexStore.dispatch(type, data)
  }

  setLastActionTime() {
    this.actionTime = Date.now()
  }

  getLastActionTime() {
    return this.actionTime
  }

  /**
   * 获取好友列表
   */
  getFriend(version = VERSION) {
    this.sendPublishMessage(FP, { version })
  }

  searchUser(keyword) {
    let content = {
      keyword: keyword,
      fuzzy: 1,
      page: 0,
    }
    this.sendPublishMessage(US, content)
  }

  sendFriendAddRequest(value) {
    this.sendPublishMessage(FAR, value)
  }

  getFriendRequest(version = VERSION) {
    this.sendPublishMessage(FRP, { version })
  }

  handleFriendRequest(value) {
    this.sendPublishMessage(FHR, value)
  }

  /**
   * 获取用户详细信息
   */
  getUserInfos(userIds) {
    // this.sendPublishMessage(UPUI, userIds)
  }

  async getUserInfo(userId) {
    // let promise = await this.sendPublishMessage(UPUI, [userId])
    // return promise
  }

  /**
   *
   * @param {用户信息} info: {
   *   type: 0
   *   value:
   * }
   */
  modifyMyInfo(info) {
    this.sendPublishMessage(MMI, info)
  }

  /**
   *
   * @param {群组id} groupId
   * @param {是否需要刷新} refresh
   */
  getGroupInfo(groupId, refresh) {
    let groupIds = []
    groupIds.push(groupId)
    this.sendPublishMessage(GPGI, groupIds)
  }

  async getGroupMember(groupId, refresh) {
    return await this.sendPublishMessage(GPGM, {
      groupId: groupId,
      version: VERSION,
    })
  }

  async addMembers(groupId, memberIds) {
    let groupMembers = []
    for (let memberId of memberIds) {
      let groupMember = new GroupMember()
      groupMember.memberId = memberId
      groupMember.type = GroupMemberType.Normal
      groupMembers.push(groupMember)
    }
    return await this.sendPublishMessage(GAM, {
      groupId: groupId,
      groupMembers: groupMembers,
    })
  }

  async kickeMembers(groupId, memberIds) {
    return await this.sendPublishMessage(GKM, {
      groupId: groupId,
      memberIds: memberIds,
    })
  }

  async createGroup(groupName, memberIds) {
    let groupInfo = new GroupInfo()
    groupInfo.name = groupName
    groupInfo.type = GroupType.Normal
    let groupMembers = []
    for (let memberId of memberIds) {
      let groupMember = new GroupMember()
      groupMember.memberId = memberId
      groupMember.type = memberId === LocalStore.getUserId() ? GroupMemberType.Owner : GroupMemberType.Normal
      groupMembers.push(groupMember)
    }
    return await this.sendPublishMessage(GC, {
      groupInfo: groupInfo,
      groupMembers: groupMembers,
    })
  }

  async modifyGroupInfo(info) {
    return await this.sendPublishMessage(GMI, info)
  }

  async quitGroup(groupId) {
    return await this.sendPublishMessage(GQ, {
      groupId: groupId,
    })
  }

  async dismissGroup(groupId) {
    return await this.sendPublishMessage(GD, {
      groupId: groupId,
    })
  }

  async recallMessage(messageUid) {
    return await this.sendPublishMessage(MR, {
      messageUid: messageUid,
    })
  }

  async getRemoteMessages(conversation, beforeUid, count) {
    return await this.sendPublishMessage(LRM, {
      beforeUid: beforeUid,
      count: count,
      conversation: conversation,
    })
  }

  pullMessage(messageId, type = 0, pullType = 0, sendMessageCount = 0) {
    this.sendPublishMessage(MP, {
      messageId: messageId,
      type: type,
      pullType: pullType,
      sendMessageCount: sendMessageCount,
    })
  }

  getUploadToken(mediaType) {
    let content = {
      mediaType: mediaType,
    }
    this.sendPublishMessage(GQNUT, content)
  }

  async getMinioUploadUrl(mediaType, key) {
    let content = {
      mediaType: mediaType,
      key,
    }
    return await this.sendPublishMessage(GMURL, content)
  }

  async modifyFriendAlias(targetUid, alias) {
    let content = {
      reason: alias,
      targetUserId: targetUid,
    }
    return await this.sendPublishMessage(FALS, content)
  }
}
