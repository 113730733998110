import store from '@/store'
import LocalStore from '@/websocket/store/localstore'
import Conversation from '../model/conversation'
import MessageStatus from './messageStatus'

/**
 * 消息格式:
 {
   "conversation":{
     "conversationType": 0,
     "target": "UZUWUWuu",
     "line": 0,
     }
   "from": "UZUWUWuu",
   "content": {
     "type": 1,
     "searchableContent": "1234",
     "pushContent": "",
     "content": "",
     "binaryContent": "",
     "localContent": "",
     "mediaType": 0,
     "remoteMediaUrl": "",
     "localMediaPath": "",
     "mentionedType": 0,
     "mentionedTargets": [ ]
   },
   "messageId": 52,
   "direction": 1,
   "status": 5,
   "messageUid": 75735276990792720,
   "timestamp": 1550849394256,
   "to": ""
 }
 */
export default class Message {
  conversation = {}
  from = ''
  content = {}
  messageId = 0
  direction = 0
  status = 0
  messageUid = 0
  timestamp = 0
  tos = ''
  // todo
  static toMessage(state, sendMessage) {
    let message = new Message()
    let target = sendMessage.target || state.selectTarget
    if (sendMessage.tos !== '') {
      message.tos = sendMessage.tos
    }
    message.content = sendMessage.messageContent
    message.from = state.userId
    message.status = MessageStatus.Sending
    message.timestamp = new Date().getTime()
    message.direction = 0
    message.messageId = new Date().getTime()
    let { conversationInfo: convInfo } =
    state.conversations.find(({ conversationInfo: convInfo }) => {
      return convInfo && convInfo.target === target
    }) || {}
    // 没找到会话
    if (!convInfo) {
      return message
    }
    message.conversation = new Conversation(convInfo.conversationType, convInfo.target, convInfo.line)
    return message
  }

  // 方法不支持重载
  static conert2Message(sendMessage) {
    let message = new Message()
    let target = sendMessage.target
    if (!target) {
      target = store.state.selectTarget
    }
    let stateConversationInfo = store.state.conversations.find(
      (conversation) => conversation.conversationInfo.target === target,
    )
    message.conversation = new Conversation(
      stateConversationInfo.conversationInfo.conversationType,
      stateConversationInfo.conversationInfo.target,
      stateConversationInfo.conversationInfo.line,
    )
    message.content = sendMessage.messageContent
    message.from = LocalStore.getUserId()
    message.status = MessageStatus.Sending
    message.timestamp = new Date().getTime()
    message.direction = 0
    message.messageId = new Date().getTime()
    return message
  }
}
