export default class MessageStatus {
  static Sending = 0
  static Sent = 1
  static SendFailure = 2
  static Mentioned = 3
  static AllMentioned = 4
  static Unread = 5
  static Readed = 6
  static Played = 7
}
