import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import LocalStore from '@/websocket/store/localstore'

Vue.use(Vuex)

const state = {
  token: localStorage.getItem('token'), // 登录态
  userType: localStorage.getItem('user-type'), // 用户类型：2-跨境好运直客、1-跨境好运加盟商、3-跨境好运管理端
  user: localStorage.getItem('userInfo'), // 用戶信息
  shopId: '', // 渠道ID
  isManageSign: '', // 渠道ID
  supplierId: '',
  userCode: '', // 直客对应id
  toType: '', // 唤起对应端(聊天对方端)
  imOrderId: '', // 对应订单id
  channelLibId: '', // 方案id
  channelId: '', // 线路id
  cityId: '', // 打开IM时携带cityId
  countryCode: '', // 打开IM时countryCode
  warehouseAddress: '', // 打开IM时warehouseAddress
  warehouseType: '', // 打开IM时warehouseType
  totalWeight: '', // 打开IM时totalWeight
  totalVolume: '', // 打开IM时totalVolume
  storeId: '', // 销售方仓位id
  storeNum: '', // 下单预计需要仓库
  // 跨境好运: 1: 服务商 2: 直客 3: 运力商
  // 货好运: 1: 加盟商  3: SAAS
  searchText: '', // 搜索文本
  imOrderType: '', // 1-fba 2-小包 3-整柜
  origin: '',

  // 用户信息
  // user: {
  //   name: 'ratel',
  //   img: this.$wj.getAvatar(),
  // },

  friendlist: [], // 好友列表
  friendIds: [],
  friendDatas: [],
  selectTarget: '', // 选中对话的 target
  firstSelectTarget: [], // 打开IM时显示推荐方案的对话id target和对应channelId
  channelInfoChat: {}, // 推荐方案在线咨询数据
  channelSelectTarget: '', // 选择推荐方案时记录当前会话id
  selectFriendId: 0, // 选中对话好友ID
  seqId: '', // 聊天历史记录seqId
  vueSocket: null,
  voipClient: null,
  groupCallClient: null,
  conversations: [], // 会话列表
  messages: [], // 消息列表
  searchUsers: [], // 搜索用户列表
  friendRequests: [],
  newFriendRequestCount: 0,
  deviceId: '',
  userId: '',
  userInfoList: [],
  groupInfoList: [],
  tempGroupMembers: [],
  notify: '',
  inCommingNotify: '',
  outGoingNotify: '',
  firstLogin: false,
  emptyMessage: false,
  fullScreenMode: false, // 全屏模式
  appHeight: 638,
  qrcodeVisible: false, // 二维码浮窗是否可见
  visibilityState: 'visible', // 页面可见性状态
  showChatBox: false, // 是否限制音视频对话框
  showAudioBox: false,
  showSearchFriendDialog: false,
  showCreateGroupDialog: false,
  showRelayMessageDialog: false,
  showGroupCallVideoDialog: false,
  groupCallMembers: [],
  showGroupInfo: false,
  showMessageRightMenu: [],
  currentRightMenuMessage: null,
  waitUserIds: [], // 待请求用户 id 列表
  groupOperateState: 0, // 0-创建群组；1-添加群组人员；2-移除群组人员；3-单聊用户创建群组；4-创建群组音视频聊天
  groupMemberMap: new Map(),
  groupMemberTracker: 0,
  isLoadRemoteMessage: false,
  seqIdList: [], // 记录最后的seqId值
  sys: true, // true 跨境好运
  toNewText: '回到底部',
  showConnectTips: false, // 连接提示框
  inputing: false,
  conversationType: '', // 会话类型：common-常规会话；service-客服会话
  isServiceConversation: false,
  serviceConversation: {}, // 平台客服会话

  // emoji 表情
  emojis: [
    { file: '100.gif', code: '/::)', title: '微笑', reg: /\/::\)/g },
    { file: '101.gif', code: '/::~', title: '伤心', reg: /\/::~/g },
    { file: '102.gif', code: '/::B', title: '美女', reg: /\/::B/g },
    { file: '103.gif', code: '/::|', title: '发呆', reg: /\/::\|/g },
    { file: '104.gif', code: '/:8-)', title: '墨镜', reg: /\/:8-\)/g },
    { file: '105.gif', code: '/::<', title: '哭', reg: /\/::</g },
    { file: '106.gif', code: '/::$', title: '羞', reg: /\/::\$/g },
    { file: '107.gif', code: '/::X', title: '哑', reg: /\/::X/g },
    { file: '108.gif', code: '/::Z', title: '睡', reg: /\/::Z/g },
    { file: '109.gif', code: '/::\'(', title: '哭', reg: /\/::'\(/g },
    { file: '110.gif', code: '/::-|', title: '囧', reg: /\/::-\|/g },
    { file: '111.gif', code: '/::@', title: '怒', reg: /\/::@/g },
    { file: '112.gif', code: '/::P', title: '调皮', reg: /\/::P/g },
    { file: '113.gif', code: '/::D', title: '笑', reg: /\/::D/g },
    { file: '114.gif', code: '/::O', title: '惊讶', reg: /\/::O/g },
    { file: '115.gif', code: '/::(', title: '难过', reg: /\/::\(/g },
    { file: '116.gif', code: '/::+', title: '酷', reg: /\/::\+/g },
    { file: '117.gif', code: '/:--b', title: '汗', reg: /\/:--b/g },
    { file: '118.gif', code: '/::Q', title: '抓狂', reg: /\/::Q/g },
    { file: '119.gif', code: '/::T', title: '吐', reg: /\/::T/g },
    { file: '120.gif', code: '/:,@P', title: '笑', reg: /\/:,@P/g },
    { file: '121.gif', code: '/:,@-D', title: '快乐', reg: /\/:,@-D/g },
    { file: '122.gif', code: '/::d', title: '奇', reg: /\/::d/g },
    { file: '123.gif', code: '/:,@o', title: '傲', reg: /\/:,@o/g },
    { file: '124.gif', code: '/::g', title: '饿', reg: /\/::g/g },
    { file: '125.gif', code: '/:|-)', title: '累', reg: /\/:\|-\)/g },
    { file: '126.gif', code: '/::!', title: '吓', reg: /\/::!/g },
    { file: '127.gif', code: '/::L', title: '汗', reg: /\/::L/g },
    { file: '128.gif', code: '/::>', title: '高兴', reg: /\/::>/g },
    { file: '129.gif', code: '/::,@', title: '闲', reg: /\/::,@/g },
    { file: '130.gif', code: '/:,@f', title: '努力', reg: /\/:,@f/g },
    { file: '131.gif', code: '/::-S', title: '骂', reg: /\/::-S/g },
    { file: '133.gif', code: '/:,@x', title: '秘密', reg: /\/:,@x/g },
    { file: '134.gif', code: '/:,@@', title: '乱', reg: /\/:,@@/g },
    { file: '135.gif', code: '/::8', title: '疯', reg: /\/::8/g },
    { file: '136.gif', code: '/:,@!', title: '哀', reg: /\/:,@!/g },
    { file: '137.gif', code: '/:!!!', title: '鬼', reg: /\/:!!!/g },
    { file: '138.gif', code: '/:xx', title: '打击', reg: /\/:xx/g },
    { file: '139.gif', code: '/:bye', title: 'bye', reg: /\/:bye/g },
    { file: '142.gif', code: '/:handclap', title: '鼓掌', reg: /\/:handclap/g },
    { file: '145.gif', code: '/:<@', title: '什么', reg: /\/:<@/g },
    { file: '147.gif', code: '/::-O', title: '累', reg: /\/::-O/g },
    { file: '153.gif', code: '/:@x', title: '吓', reg: /\/:@x/g },
    { file: '155.gif', code: '/:pd', title: '刀', reg: /\/:pd/g },
    { file: '156.gif', code: '/:<W>', title: '水果', reg: /\/:<W>/g },
    { file: '157.gif', code: '/:beer', title: '酒', reg: /\/:beer/g },
    { file: '158.gif', code: '/:basketb', title: '篮球', reg: /\/:basketb/g },
    { file: '159.gif', code: '/:oo', title: '乒乓', reg: /\/:oo/g },
    { file: '195.gif', code: '/:circle', title: '跳舞', reg: /\/:circle/g },
    { file: '160.gif', code: '/:coffee', title: '咖啡', reg: /\/:coffee/g },
  ],
  newemojis: [
    '😀', '😃', '😄', '😁', '😆', '🤣', '😂', '😅', '😊', '😇', '🙂', '🙃', '😘', '😍', '😌', '😉', '😗', '😙', '😚', '😋', '😛', '😜', '😝', '🤓', '😎', '😟', '😔', '😞', '😒', '😏', '😕', '🙁', '😣', '😖', '😀', '😃', '😄', '😁', '😆', '🤣', '😂', '😅', '😊', '😇', '🙂', '🙃', '😘', '😍', '😌', '😉', '😗', '😙', '😚', '😋', '😛', '😜', '😝', '🤓', '😎', '😟', '😔', '😞', '😒', '😏', '😕', '🙁', '😣', '😖',
  ],
  readTimestamp: 0,
  chartListPageInfo: {
    page: 1,
    size: 10,
  },
  chatListNomore: false,
  // 查询条件参数
  rootParams: {},
  showNetworkIn: false,
  showNetworkOut: false,
  showAddEnquiry: false,
  showTransportation: false,
  showEnquiryInfo: false,
  showChannelMessage: false, // 点击方案显示最近消息
  isAInquiry: false, // 是否单独询价
  showLookEnquiry: false, // 查看询价详情
  publishId: '', // 查看询价详情id
  enquiryInfo: {}, // 询价info
  pageInfo: {}, // page 位置信息
  copyInfo: {
    pageX: 0,
    pageY: 0,
    text: '',
    type: '',
    target: null,
  }, // 复制信息
  showCopy: false,
  showChannel: true, // 显示推荐方案
  imStatus: 1, // 0： 禁用，1：启用
  oldMarkName: '',
}

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions,
})
store.watch(
  (state) => state.conversations,
  (value) => {
    LocalStore.saveConverSations(value)
  },
  {
    deep: true,
  },
)

store.watch(
  (state) => state.messages,
  (value) => {
    if (state.isServiceConversation) {
      return
    }
    LocalStore.saveMessages(value)
  },
  {
    deep: true,
  },
)

store.watch(
  (state) => state.userInfoList,
  (value) => {
    LocalStore.saveUserInfoList(value)
  },
  {
    deep: true,
  },
)

store.watch(
  (state) => state.selectTarget,
  (value) => {
    LocalStore.setSelectTarget(value)
  },
  {
    deep: true,
  },
)

store.watch(
  (state) => state.selectFriendId,
  (value) => {
    LocalStore.setSelectFriendId(value)
  },
  {
    deep: true,
  },
)
store.watch(
  (state) => state.seqIdList,
  (value) => {
    LocalStore.setSeqIdList(value)
  },
  {
    deep: true,
  },
)

store.watch(
  (state) => state.readTimestamp,
  (value) => {
    sessionStorage.setItem('readTimestamp', value)
  },
  {
    deep: true,
  },
)

export default store
