// 通用环境变量 => 根据域名判断环境
export let envname = process.env.NODE_ENV === 'production' ? 'pro' : 'dev' // 正式环境
export const subdomain = location.hostname.split('.')[0]
switch (subdomain) {
  case 'localhost':
  case 'loc':
  case 'dev': // 本地/开发环境
    envname = 'dev'
    break
  case 'test': // 测试环境
    envname = 'test'
    break
  case 'pre': // 预发布环境
    envname = 'pre'
    break
}
export const host = location.host
export const sld = envname === 'pro' ? '' : envname + '.' // 二级域名（带.）
export { envname as name }
