export const version = process.env.VUE_APP_VERSION
export const source = 4 // 请求来源：1-安卓；2-IOS；3-微信；4-web；5-wxmp；6-h5
export const isProd = process.env.NODE_ENV === 'production'
export const isOnline = location.hostname === 'chat.kjhaoyun.com'
export const amapkey = isOnline ? '672c4aab17fbf5145f67c0db1b44a61d' : 'cb32d3a2065cfada82bee7f2a4e8c9cc'
export const statid = isOnline ? 'feeddb202e0adf88584daf6aa636b722' : 'd8277fd5c4d2b79f1d3e02af5dd552bb'
export const assets = 'https://static.kjhaoyun.com/kjhaoyun' // 在线静态资源路径

// 根据环境设置接口地址
export let env = 'pro'
export let apiUrl = '/api'
export let socketUrl = 'wss://ws-im.kjhaoyun.com/im/chat'
export let wwwUrl = 'https://www.kjhaoyun.com/'
let envname = process.env.VUE_APP_ENV || location.hostname.split('.')[0]
switch (envname) {
  case 'localhost': // 本地环境
  case '192': // 内网访问
  case 'dev': // 开发环境
  case 'dev-chat': // 开发域名
    env = 'dev'
    socketUrl = 'ws://dev-ws-im.kjhaoyun.com/im/chat'
    wwwUrl = 'http://dev-www.kjhaoyun.com/'
    break
  case 'test': // 测试环境
  case 'test-chat': // 测试域名
    env = 'test'
    socketUrl = 'wss://test-ws-im.kjhaoyun.com/im/chat'
    wwwUrl = 'https://test-www.kjhaoyun.com/'
    break
}

// 解决嵌入端与聊天系统的 iframe 跨域问题。在跨域情况下此方式已彻底废了！
if (location.hostname.endsWith('kjhaoyun.com')) {
  document.domain = 'kjhaoyun.com'
}

// 解决嵌入端与聊天系统的 iframe 跨域问题。在跨域情况下此方式已彻底废了！
if (location.hostname.endsWith('17haoyun.cn')) {
  document.domain = '17haoyun.cn'
}

// WS 常量
export const HEART_BEAT_INTERVAL = 5 * 1000 // 心跳间隔 5s
export const RECONNECT_INTERVAL = 15 * 1000 // 重连间隔 15s
export const BINTRAY_TYPE = 'blob' // 指定收到的二进制数据类型
export const VERSION = 1 // 版本号

// 消息信令
export const CONNECT = 'CONNECT' // 建立连接
export const CONNECT_FAIL = 'CONNECT_REFUSE_BAD_USER' // 拒绝连接-认证失败
export const CONNECT_SUCCESS = 'CONNECT_SUCCESS' // 连接成功
export const DISCONNECT = 'DISCONNECT' // 断开连接
export const PING = 'PING' // 心跳
export const PUBLISH = 'PUBLISH' // 发送消息
export const PUB_ACK = 'PUBLISH_ACK' // 确认收到消息
export const MSG_READED = 'MSG_READ' // 消息已读
export const NEW_MSG = 'NEW_MSG' // 有新消息到达
export const SESSION = 'SESSION' // 获取会话
export const SESSION_ACK = 'SESSION_ACK' // 获取会话结果
export const ERR = 'ERR' // 出错

// 子信令
export const FRP = 'FRP'
export const FP = 'FP'
export const FALS = 'FALS'
export const UPUI = 'UPUI'
export const GPGI = 'GPGI'
export const GPGM = 'GPGM'
export const GAM = 'GAM'
export const GC = 'GC'
export const GMI = 'GMI'
export const GKM = 'GKM'
export const GQ = 'GQ'
export const GD = 'GD'
export const MP = 'MP'
export const MS = 'MS'
export const MN = 'MN'
export const MR = 'MR'
export const RMN = 'RMN'
export const GQNUT = 'GQNUT'
export const GMURL = 'GMURL'
export const US = 'US'
export const FAR = 'FAR'
export const FRN = 'FRN'
export const FHR = 'FHR'
export const FN = 'FN'
export const MMI = 'MMI'
export const LRM = 'LRM'

// 缓存 key
export const KEY_TOKEN = 'token'

// 状态码
export const ERROR_CODE = 400
export const SUCCESS_CODE = 200

// 会话最大消息数
export const CONVERSATION_MAX_MESSAGE_SIZE = 50

// 是否使用七牛上传文件
export const UPLOAD_BY_QINIU = false

// 阿里云 OSS 配置
export const alioss = {
  bucket: 'ichat',
  region: 'oss-cn-shenzhen',
  // accessKeyId: 'xxx',
  // accessKeySecret: 'xxx',
}
