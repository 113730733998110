// import * as wj from '@/common/wjtools'
import ConversationType from '@/websocket/model/conversationType'

export default {
  // ====================================================================== 购物车
  // 获取购物车商品总数量
  // goodsNum(state) {
  //   let totalNum = 0
  //   for (let i = 0; i < state.shoppingCart.length; i++) {
  //     const temp = state.shoppingCart[i]
  //     totalNum += temp.num
  //   }
  //   return totalNum
  // },

  currentGroupMembers({ groupMemberMap, selectTarget }) {
    return groupMemberMap.has(selectTarget) ? groupMemberMap.get(selectTarget) : []
  },

  // 筛选会话列表
  searchedConversationList(state) {
    return state.conversations.filter(item => {
      // let firstChar = item.conversationInfo && item.conversationInfo.target && item.conversationInfo.target.substr(0, 2)
      // return firstChar !== '-1'
      return item.markName !== '平台客服'
    })
  },

  // 当前会话是否为单聊会话
  isSingleConversation(state) {
    let stateConversation = state.conversations.find(stateConversation => stateConversation.conversationInfo.target === state.selectTarget)
    return !!stateConversation && stateConversation.conversationInfo.conversationType === ConversationType.Single
  },

  // 搜索结果-好友列表
  searchedFriendlist(state) {
    // 根据用户昵称拼音首字母进行分类
    let friendMap = new Map()
    let noInitFriendList = []
    let allFriendList = []
    for (let friendOrigin of state.friendlist) {
      let friend = {
        id: friendOrigin.id,
        wxid: friendOrigin.wxid, // 微信号
        initial: friendOrigin.initial, // 姓名首字母
        img: friendOrigin.img, // 头像
        signature: friendOrigin.signature, // 个性签名
        nickname: friendOrigin.nickname, // 昵称
        sex: friendOrigin.sex, // 性别：1-男；0-女
        remark: friendOrigin.remark, // 备注
        area: friendOrigin.area, // 地区
      }
      if (friend.id === 0) {
        continue
      }
      if (friend.initial) {
        let initalFriendList = friendMap.get(friend.initial)
        if (initalFriendList) {
          friend.initial = ''
          initalFriendList.push(friend)
        } else {
          initalFriendList = []
          initalFriendList.push(friend)
          friendMap.set(friend.initial, initalFriendList)
        }
      } else {
        noInitFriendList.push(friend)
      }
    }
    if (state.friendlist.length > 0) {
      allFriendList.push(state.friendlist[0])
    }
    if (noInitFriendList.length > 0) {
      for (let friend of noInitFriendList) {
        allFriendList.push(friend)
      }
    }
    for (let [friendList] of friendMap) {
      for (let friend of friendList) {
        allFriendList.push(friend)
      }
    }
    let friends = allFriendList.filter(friend => friend.remark.includes(state.searchText))
    return friends
  },

  onlyFriendlist(state) {
    let friends = state.friendlist.slice(1, state.friendlist.length)
    let listunCheckedFriends = []
    for (let friend of friends) {
      listunCheckedFriends.push({
        id: friend.id,
        wxid: friend.wxid,
        remark: friend.remark,
        img: friend.img,
        initial: friend.initial,
        checked: false,
      })
    }
    return listunCheckedFriends
  },
  // 通过当前选择是哪个在线咨询匹配相应的对话
  channelSelectedChat(state) {
    let chatMessage = state.messages.find(chatMessage => chatMessage.target === state.channelSelectTarget)
    if (!chatMessage) {
      const conv = state.conversations.find(conv => conv.conversationInfo.target === state.channelSelectTarget)
      chatMessage = {
        name: conv && conv.name,
        markName: conv && conv.markName,
        target: state.selectTarget,
        protoMessages: [],
        type: conv && conv.type,
      }
    }
    return chatMessage
  },
  // 通过当前选择是哪个对话匹配相应的对话
  selectedChat(state) {
    let chatMessage = state.messages.find(chatMessage => chatMessage.target === state.selectTarget)
    if (!chatMessage) {
      const conv = state.conversations.find(conv => conv.conversationInfo.target === state.selectTarget)
      chatMessage = {
        name: conv && conv.name,
        markName: conv && conv.markName,
        target: state.selectTarget,
        protoMessages: [],
        type: conv && conv.type,
      }
    }
    return chatMessage
  },
  // 通过当前选择是哪个对话框(从会话列表获取)
  selectedCov(state) {
    const conv = state.conversations.find(conv => conv.conversationInfo.target === state.selectTarget)
    let chatMessage = {
      name: conv && conv.name,
      markName: conv && conv.markName,
      target: state.selectTarget,
      protoMessages: [],
      type: conv && conv.type,
    }
    return chatMessage
  },

  // 通过当前选择是哪个好友匹配相应的好友
  selectedFriend(state) {
    let friend = state.friendlist.find(friend => friend.id === state.selectFriendId)
    return friend
  },

  // TODO
  messages(state) {
    let chatMessage = state.messages.find(chatMessage => chatMessage.target === state.selectTarget)
    return chatMessage ? chatMessage.protoMessages : []
  },

  // 未读消息统计
  unreadTotalCount(state) {
    let total = 0
    if (state.conversations) {
      for (let stateConversationInfo of state.conversations) {
        if (stateConversationInfo.conversationInfo.unreadCount) {
          total += stateConversationInfo.conversationInfo.unreadCount.unread
        }
      }
    }
    if (total === 0) {
      state.notify.faviconClear()
      state.notify.setTitle()
      state.notify.close()
    } else {
      state.notify.setFavicon(total)
      state.notify.setTitle('你有新的消息未读')
    }
    return total
  },
}
