import MessageContentType from '../messageContentType'

import GroupNotificationContent from './groupNotification'
import StringUtils from '@/websocket/utils/StringUtil'
import webSocketCli from '@/websocket/websocketcli'

export default class DismissGroupNotification extends GroupNotificationContent {
    operator = '';

    constructor(operator) {
      super(MessageContentType.DismissGroup_Notification)
      this.operator = operator
    }

    formatNotification() {
      if (this.fromSelf) {
        return '您解散了群组'
      } else {
        return webSocketCli.getDisplayName(this.operator) + '解散了群组'
      }
    }

    encode() {
      let payload = super.encode()
      let obj = {
        g: this.groupId,
        o: this.operator,
      }
      payload.binaryContent = StringUtils.utf8ToB64(JSON.stringify(obj))
      return payload
    }

    decode(payload) {
      super.decode(payload)
      let json = StringUtils.b64ToUtf8(payload.binaryContent)
      let obj = JSON.parse(json)
      this.groupId = obj.g
      this.operator = obj.o
    }
}
