import NotificationMessageContent from './notificationMessageContent'
import MessageContentType from '../messageContentType'
import StringUtils from '@/websocket/utils/StringUtil'
import webSocketCli from '@/websocket/websocketcli'

export default class RecallMessageNotification extends NotificationMessageContent {
    operatorId = '';
    messageUid = '';

    constructor(operatorId, messageUid) {
      super(MessageContentType.RecallMessage_Notification)
      this.operatorId = operatorId
      this.messageUid = messageUid
    }

    formatNotification() {
      if (this.fromSelf) {
        return '您撤回了一条消息'
      } else {
        return webSocketCli.getDisplayName(this.operatorId) + '撤回了一条消息'
      }
    }

    encode() {
      let payload = super.encode()
      payload.content = this.operatorId
      payload.binaryContent = StringUtils.utf8ToB64(this.messageUid.toString())
      return payload
    };

    decode(payload) {
      super.decode(payload)
      this.operatorId = payload.content
      this.messageUid = StringUtils.b64ToUtf8(payload.binaryContent)
    }
}
