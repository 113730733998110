<template>
  <div id="app" class="app" @click="stopClick">
    <router-view />

    <!-- 二维码浮窗 -->
    <div v-if="qrcodeVisible" class="floater-qrcode animate__animated animate__bounceInRight">
      <img src="@/assets/chat/floater-qrcode.png">
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'App',
    created() {
      // 进入聊天后缓存平台传参
      const {
        token,
        isManageSign,
        userType,
        shopId,
        shopIdName,
        toId,
        sys,
        supplierId,
        userCode,
        toType,
        imOrderId,
        channelId,
        channelLibId,
        cityId,
        countryCode,
        warehouseAddress,
        warehouseType,
        totalWeight,
        totalVolume,
        storeId,
        storeNum,
        origin,
        imOrderType,
      } = this.$wj.getQuery(location.href) || {}
      if (token && userType) {
        this.$wj.setLogin({ token, userType })
        this.setInitHref({
          isManageSign,
          userType,
          shopId,
          shopIdName,
          toId,
          sys,
          supplierId,
          userCode,
          toType,
          imOrderId,
          channelId,
          channelLibId,
          cityId,
          countryCode,
          warehouseAddress,
          warehouseType,
          totalWeight,
          totalVolume,
          storeId,
          storeNum,
          origin,
          imOrderType,
        })
        if (shopId && shopIdName) {
          let num = localStorage.getItem('networkInNum') || 0
          // 方案在线咨询
          if (num < 3) {
            localStorage.setItem('networkInNum', ++num)
            setTimeout(() => {
              this.setShowNetworkIn(true)
            }, 1000)
          }
        }
      } else {
        let token = localStorage.getItem('token')
        let userType = localStorage.getItem('user-type')
        if (!(token && userType)) {
          this.$message.error('缺少必要参数：[用户信息]')
        }
      }
      // 管理后台的会话
      if (toId) {
        sessionStorage.setItem('friend-id', toId)
      }
      this.setSys(sys === 'KGJ')
      sys ? localStorage.setItem('system-type', sys) : localStorage.removeItem('system-type')
      if (Notification.permission !== 'granted') {
        // 请求权限
        Notification.requestPermission()
      }
    },
    computed: {
      ...mapState(['qrcodeVisible']),
    },
    methods: {
      ...mapMutations(['setSys', 'setInitHref', 'setShowNetworkIn', 'setShowCopy']),
      stopClick() {
        this.setShowCopy(false)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .floater-qrcode
    position fixed
    bottom calc((100vh - 650px) / 2)
    right 1vw
    img
      display block
      width 272px
      height auto

  // 小屏模式
  // @media screen and (max-width: 1442px)
  //   .floater-qrcode
  //     // display none
  //     animation-name bounceOutRight
</style>
