import ChatManager from '@/websocket/chatManager'
import MessageConfig from '@/websocket/message/messageConfig'
import SendMessage from '@/websocket/message/sendMessage'
import OnReceiverMessageListener from '@/websocket/listener/onReceiverMessageListener'
import CallStartMessageContent from './message/callStartMessageContent'
import CallAnswerMessageContent from './message/callAnswerMessageContent'
import CallSignalMessageContent from './message/callSignalMessageContent'
import CallAnswerTMessageContent from './message/callAnswerTMessageContent'
import CallByeMessageContent from './message/callByeMessageContent'
import CallSession from './callSession'
import CallState from './callState'
import CallEndReason from './callEndReason'

export default class VoipClient extends OnReceiverMessageListener {
    static store
    myPeerConnection;
    webcamStream;
    mediaConstraints = {
      audio: true, // We want an audio track
      video: true,
    };

    sender;

    // 是否为createOff发起人
    isInitiator;

    // 当前会话
    currentSession;
    currentSessionCallback;
    currentEngineCallback;

    constructor(store) {
      super()
      // this.store = store // todo:使用类属性会报 Maximum call stack size exceeded 错误
      VoipClient.store = store
      ChatManager.addReceiveMessageListener(this)
    }

    setCurrentSessionCallback(sessionCallback) {
      this.currentSessionCallback = sessionCallback
    }

    setCurrentEngineCallback(engineCallback) {
      this.currentEngineCallback = engineCallback
    }

    startCall(target, isAudioOnly) {
      this.isInitiator = false
      // 创建 session
      let newSession = this.newSession(target, isAudioOnly, target + new Date().getTime())
      newSession.setState(CallState.STATUS_OUTGOING)
      this.currentSession = newSession
      // 发送callmessage
      let callStartMessageContent = new CallStartMessageContent(newSession.callId, target, isAudioOnly)
      this.offerMessage(target, callStartMessageContent)
      // 如果时视频，启动预览
      this.startPreview()
    }

    cancelCall() {
      let byeMessage = new CallByeMessageContent()
      byeMessage.callId = this.currentSession.callId
      this.offerMessage(this.currentSession.clientId, byeMessage)
      this.currentSession.endCall(CallEndReason.REASON_RemoteHangup)
      this.currentSession = null
    }

    answerCall(audioOnly) {
      this.isInitiator = true
      this.currentSession.setState(CallState.STATUS_CONNECTING)
      let answerTMesage = new CallAnswerTMessageContent()
      answerTMesage.isAudioOnly = audioOnly
      answerTMesage.callId = this.currentSession.callId
      this.offerMessage(this.currentSession.clientId, answerTMesage)
      this.startPreview()
    }

    newSession(clientId, audioOnly, callId) {
      let session = new CallSession(this)
      session.clientId = clientId
      session.audioOnly = audioOnly
      session.callId = callId
      session.sessionCallback = this.currentSessionCallback
      return session
    }

    rejectOtherCall(callId, clientId) {
      let byeMessage = new CallByeMessageContent()
      byeMessage.callId = callId
      this.log('reject other callId ' + callId + ' clientId ' + clientId)
      this.offerMessage(clientId, byeMessage)
    }

    offerMessage(target, messageConent) {
      // this.store.dispatch('sendMessage', new SendMessage(target, messageConent))
      VoipClient.store.dispatch('sendMessage', new SendMessage(target, messageConent))
    }

    offerMessageByType(type) {
      let callSignalMessageContent = new CallSignalMessageContent()
      callSignalMessageContent.callId = this.currentSession.callId
      let jsonPayload = {
        type: type,
        sdp: this.myPeerConnection.localDescription.sdp,
      }
      callSignalMessageContent.payload = JSON.stringify(jsonPayload)
      this.offerMessage(this.currentSession.clientId, callSignalMessageContent)
    }

    /**
     * 接收信令服务传递过来的消息
     */
    onReceiveMessage(protoMessage) {
      // 只处理接收消息，对于同一用户不同session会话忽略
      if (new Date().getTime() - protoMessage.timestamp < 90000 && protoMessage.direction === 1 && protoMessage.conversationType === 0) {
        let MessageContent = MessageConfig.getMessageContentClazz(protoMessage.content.type)
        if (MessageContent) {
          let content = new MessageContent()
          try {
            content.decode(protoMessage.content)
          } catch (err) {
            console.err(err)
          }
          if (content instanceof CallStartMessageContent) {
            if (this.currentSession && this.currentSession.callState !== CallState.STATUS_IDLE) {
              this.rejectOtherCall(content.callId, protoMessage.from)
            } else {
              this.currentSession = this.newSession(protoMessage.from, content.audioOnly, content.callId)
              this.currentSession.setState(CallState.STATUS_INCOMING)
              this.currentEngineCallback.onReceiveCall(this.currentSession)
            }
          } else if (content instanceof CallAnswerMessageContent || content instanceof CallAnswerTMessageContent) {
            this.isInitiator = false
            if (this.currentSession && this.currentSession.callState !== CallState.STATUS_IDLE) {
              if (protoMessage.from === this.currentSession.clientId && content.callId === this.currentSession.callId) {
                if (this.currentSession.callState !== CallState.STATUS_OUTGOING) {

                  // this.rejectOtherCall(this.currentSession.callId,this.currentSession.clientId);
                } else if (this.currentSession.callState === CallState.STATUS_OUTGOING) {
                  this.currentSession.setState(CallState.STATUS_CONNECTING)
                }
              }
            }
          } else if (content instanceof CallSignalMessageContent) {
            if (this.currentSession && this.currentSession.callState !== CallState.STATUS_IDLE) {
              if (this.currentSession.callState === CallState.STATUS_CONNECTING || this.currentSession.callState === CallState.STATUS_CONNECTED) {
                if (protoMessage.from === this.currentSession.clientId && content.callId === this.currentSession.callId) {
                  this.handleSignalMsg(content.payload)
                }
              } else {
                this.currentSession.endCall(CallEndReason.REASON_AcceptByOtherClient)
                // this.currentSession.sessionCallback.didCallEndWithReason(CallEndReason.REASON_AcceptByOtherClient);
              }
            }
          } else if (content instanceof CallByeMessageContent) {
            if (!this.currentSession || this.currentSession.callState === CallState.STATUS_IDLE || protoMessage.from !== this.currentSession.clientId || content.callId !== this.currentSession.callId) {
              return
            }
            this.cancelCall()
          }
        }
      }
    }

    async handleSignalMsg(payload) {
      let signalMessage = JSON.parse(payload)
      let type = signalMessage.type
      if (type === 'candidate') {
        let rTCIceCandidateInit = {
          candidate: signalMessage.candidate,
          sdpMLineIndex: signalMessage.label,
          sdpMid: signalMessage.id,
        }
        let candidate = new RTCIceCandidate(rTCIceCandidateInit)
        try {
          await this.myPeerConnection.addIceCandidate(candidate)
        } catch (err) {
          this.reportError(err)
        }
      } else if (type === 'remove-candidates') {
        this.log('remove candidates ')
      } else {
        let desc = new RTCSessionDescription(signalMessage)
        if (type === 'answer') {
          await this.myPeerConnection.setRemoteDescription(desc)
        } else if (type === 'offer') {
          await this.myPeerConnection.setRemoteDescription(desc)
          await this.myPeerConnection.setLocalDescription(await this.myPeerConnection.createAnswer())
          // send answer message
          let callSignalMessageContent = new CallSignalMessageContent()
          callSignalMessageContent.callId = this.currentSession.callId
          let jsonPayload = {
            type: 'answer',
            sdp: this.myPeerConnection.localDescription.sdp,
          }
          callSignalMessageContent.payload = JSON.stringify(jsonPayload)
          this.offerMessage(this.currentSession.clientId, callSignalMessageContent)
        }
      }
    }

    async handleOfferMessage() {
      if (!this.isInitiator) {
        return
      }
      try {
        const offer = await this.myPeerConnection.createOffer()

        // If the connection hasn't yet achieved the "stable" state,
        // return to the caller. Another negotiationneeded event
        // will be fired when the state stabilizes.

        if (this.myPeerConnection.signalingState !== 'stable') {
          return
        }

        // Establish the offer as the local peer's current
        // description.

        await this.myPeerConnection.setLocalDescription(offer)

        // Send the offer to the remote peer.

        this.offerMessageByType('offer')
      } catch (err) {
        this.reportError(err)
      };
    }

    async startPreview() {
      this.log('Starting to prepare an invitation')
      if (this.myPeerConnection) {
        alert('You can\'t start a call because you already have one open!')
      } else {
        // Get access to the webcam stream and attach it to the
        // "preview" box (id "local_video").

        try {
          this.mediaConstraints = {
            audio: true, // We want an audio track
            video: !this.currentSession.isAudioOnly,
          }
          this.webcamStream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints)
          if (!this.currentSession.isAudioOnly) {
            this.currentSessionCallback.didCreateLocalVideoTrack(this.webcamStream)
          }
        } catch (err) {
          this.handleGetUserMediaError(err)
          return
        }

        // Call createPeerConnection() to create the RTCPeerConnection.
        // When this returns, myPeerConnection is our RTCPeerConnection
        // and webcamStream is a stream coming from the camera. They are
        // not linked together in any way yet.

        this.createPeerConnection()

        // Add the tracks from the stream to the RTCPeerConnection

        try {
          this.webcamStream.getTracks().forEach(track => (this.sender = this.myPeerConnection.addTrack(track, this.webcamStream)))
        } catch (err) {
          this.handleGetUserMediaError(err)
        }
      }
    }

    async createPeerConnection() {
      this.log('Setting up a connection...')

      // Create an RTCPeerConnection which knows to use our chosen
      // STUN server.

      this.myPeerConnection = new RTCPeerConnection({
        iceServers: [ // Information about ICE servers - Use your own!
          {
            urls: 'turn:turn.fsharechat.cn:3478', // A TURN server
            username: 'comsince',
            credential: 'comsince',
          },
        ],
      })

      // Set up event handlers for the ICE negotiation process.

      this.myPeerConnection.onicecandidate = this.handleICECandidateEvent
      this.myPeerConnection.oniceconnectionstatechange = this.handleICEConnectionStateChangeEvent
      this.myPeerConnection.onicegatheringstatechange = this.handleICEGatheringStateChangeEvent
      this.myPeerConnection.onsignalingstatechange = this.handleSignalingStateChangeEvent
      this.myPeerConnection.onnegotiationneeded = this.handleNegotiationNeededEvent
      this.myPeerConnection.ontrack = this.handleTrackEvent
    }

    // Called by the WebRTC layer to let us know when it's time to
    // begin, resume, or restart ICE negotiation.

    handleNegotiationNeededEvent = () => {
      this.handleOfferMessage()
    }

    // Handles |icecandidate| events by forwarding the specified
    // ICE candidate (created by our local ICE agent) to the other
    // peer through the signaling server.
    // 接收来自信令服务器发送来的ICE candidate事件消息
    handleICECandidateEvent = (event) => {
      if (event.candidate) {
        let candidateMessageContent = new CallSignalMessageContent()
        candidateMessageContent.callId = this.currentSession.callId
        let candidatePayload = {
          type: 'candidate',
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate,
        }
        candidateMessageContent.payload = JSON.stringify(candidatePayload)
        this.offerMessage(this.currentSession.clientId, candidateMessageContent)
      }
    }

    // Handle |iceconnectionstatechange| events. This will detect
    // when the ICE connection is closed, failed, or disconnected.
    //
    // This is called when the state of the ICE agent changes.
    handleICEConnectionStateChangeEvent = (event) => {
      switch (this.myPeerConnection.iceConnectionState) {
        case 'connected':
          this.currentSession.setState(CallState.STATUS_CONNECTED)
          break
        case 'closed':
        case 'failed':
        case 'disconnected':
          this.cancelCall()
          break
      }
    }

    // Set up a |signalingstatechange| event handler. This will detect when
    // the signaling connection is closed.
    //
    // NOTE: This will actually move to the new RTCPeerConnectionState enum
    // returned in the property RTCPeerConnection.connectionState when
    // browsers catch up with the latest version of the specification!
    handleSignalingStateChangeEvent = (event) => {
      switch (this.myPeerConnection.signalingState) {
        case 'closed':
          this.cancelCall()
          break
      }
    }

    // Called by the WebRTC layer when events occur on the media tracks
    // on our WebRTC call. This includes when streams are added to and
    // removed from the call.
    //
    // track events include the following fields:
    //
    // RTCRtpReceiver       receiver
    // MediaStreamTrack     track
    // MediaStream[]        streams
    // RTCRtpTransceiver    transceiver
    //
    // In our case, we're just taking the first stream found and attaching
    // it to the <video> element for incoming media.
    handleTrackEvent = (event) => {
      if (this.currentSession.isAudioOnly) {
        this.currentSessionCallback.didReceiveRemoteAudioTrack(event.streams[0])
      } else {
        this.currentSessionCallback.didReceiveRemoteVideoTrack(event.streams[0])
      }
    }

    handleICEGatheringStateChangeEvent = (event) => {
    }

    // Close the RTCPeerConnection and reset variables so that the user can
    // make or receive another call if they wish. This is called both
    // when the user hangs up, the other user hangs up, or if a connection
    // failure is detected.

    closeCall() {
      this.log('Closing the call')
      // Close the RTCPeerConnection

      if (this.myPeerConnection) {
        this.log('--> Closing the peer connection')

        // Disconnect all our event listeners; we don't want stray events
        // to interfere with the hangup while it's ongoing.

        this.myPeerConnection.ontrack = null
        this.myPeerConnection.onnicecandidate = null
        this.myPeerConnection.oniceconnectionstatechange = null
        this.myPeerConnection.onsignalingstatechange = null
        this.myPeerConnection.onicegatheringstatechange = null
        this.myPeerConnection.onnotificationneeded = null

        // Stop all transceivers on the connection

        // this.myPeerConnection.getTransceivers().forEach(transceiver => {
        //     transceiver.stop();
        // });

        this.myPeerConnection.removeTrack(this.sender)

        // Stop the webcam preview as well by pausing the <video>
        // element, then stopping each of the getUserMedia() tracks
        // on it.

        if (!this.currentSession.isAudioOnly) {
          let localVideo = document.getElementById('wxCallLocalVideo')
          if (localVideo.srcObject) {
            localVideo.pause()
            localVideo.srcObject.getTracks().forEach(track => {
              track.stop()
            })
          }
        } else {
          this.webcamStream.getTracks.forEach(
            track => {
              track.stop()
            },
          )
        }

        // Close the peer connection

        this.myPeerConnection.close()
        this.myPeerConnection = null
        this.webcamStream = null
      }

      // Disable the hangup button

      // document.getElementById("hangup-button").disabled = true;
      // targetUsername = null;
    }

    // Handle errors which occur when trying to access the local media
    // hardware; that is, exceptions thrown by getUserMedia(). The two most
    // likely scenarios are that the user has no camera and/or microphone
    // or that they declined to share their equipment when prompted. If
    // they simply opted not to share their media, that's not really an
    // error, so we won't present a message in that situation.

    handleGetUserMediaError(e) {
      this.error(e)
      switch (e.name) {
        case 'NotFoundError':
          alert('Unable to open your call because no camera and/or microphone' +
                'were found.')
          break
        case 'SecurityError':
        case 'PermissionDeniedError':
          // Do nothing; this is the same as the user canceling the call.
          break
        default:
          alert('Error opening your camera and/or microphone: ' + e.message)
          break
      }
      this.cancelCall()
    }

    reportError(errMessage) {
      this.error(`Error ${errMessage.name}: ${errMessage.message}`)
    }

    error(text) {
      let time = new Date()
      console.trace('[' + time.toLocaleTimeString() + '] ' + text)
    }

    log(text) {
      // let time = new Date()
    }
}
