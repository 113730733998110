import { CONNECT_FAIL } from '@/config'
// import LocalStore from '@/websocket/store/localstore'
import AbstractMessageHandler from './abstractMessageHandler'

// 连接失败处理器
export default class ConnectFailHandler extends AbstractMessageHandler {
  match(data) {
    return data.signal === CONNECT_FAIL
  }

  processMessage(data) {
    // console.log('连接失败处理器', data)
    // let msgContent = data.content
    // console.warn('连接失败' + msgContent.friendHead + ' messageHead ' + msgContent.messageHead)
    console.warn('连接失败')
  }
}
