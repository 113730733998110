// 阿里 OSS 服务端签名直传接口封装
// 参考文档：https://help.aliyun.com/document_detail/31927.html?spm=a2c4g.11186623.6.1736.53837d9cqb6nj7

// import OSS from 'ali-oss'
import { Message } from 'element-ui'
import { alioss } from '@/config'
import { getOssSign } from '@/api'
import { http } from '@/common/wjtools'

// let signExpire = 0 // 签名有效期
let signData // 签名数据

// 上传
export async function upload(file) {
  let signData = await this.getSign()

  // 获取签名出错
  if (!signData.policy) {
    console.warn('获取阿里云签名出错', signData)
    return
  }

  let filename = file.name
  let filepath = `${signData.dir}/${filename}`
  let url = signData.host
  let data = {
    key: filepath,
    policy: signData.policy,
    signature: signData.signature,
    OSSAccessKeyId: signData.accessKeyId,
    success_action_status: '200', // //让服务端返回200，不设置则默认返回204
    // callback: signData.callback, // 可选：外网不可访问会报 400
    file: file,
  }

  let res = await http.upload(url, data)
  if (!res || res.status !== 200) {
    Message.error('图片上传失败!')
    return
  }
  return `${url}/${filepath}`
}

// 下载
export function download(key) {
  // todo
}

// 获取签名：如果已过期则重新取，3s 作为缓冲
export async function getSign(obecjtKey) {
  // let now = Date.parse(new Date()) / 1000
  // if (signExpire > now + 297 && signData) {
  //   return signData
  // }
  let res = await getOssSign() || {}
  if (res.code !== '200') {
    Message.error('获取阿里云签名出错：' + res.msg)
  }
  signData = res.data || {}
  // signExpire = parseInt((signData.curretTime + signData.expireTime) / 1000) || now + 300
  return signData
}

// 获取文件路径
export function getFileUrl(key) {
  if (!key) {
    // console.log('缺少必要参数：file key')
    return ''
  }
  return `https://${alioss.bucket}.${alioss.region}.aliyuncs.com/${key}`
}

// export default {
//   install(Vue) {
//     Vue.prototype.alioss = {
//       upload,
//       download,
//     }
//   },
// }
