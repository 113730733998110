import { CONNECT_SUCCESS } from '@/config'
import LocalStore from '@/websocket/store/localstore'
import AbstractMessageHandler from './abstractMessageHandler'

// 连接成功处理器
export default class ConnectSuccessHandler extends AbstractMessageHandler {
  match(data) {
    return data.signal === CONNECT_SUCCESS
  }

  processMessage(data) {
    // let msgContent = JSON.parse(data.content)

    // 拉取朋友列表
    // this.vueWebsocket.getFriend()

    let lastMessageSeq = LocalStore.getLastMessageSeq()
    if (!lastMessageSeq) {
      // lastMessageSeq = 0
      this.vueWebsocket.sendAction('changetFirstLogin', true)
    } else {
      this.vueWebsocket.sendAction('changetFirstLogin', false)
    }

    // 好友请求信息
    // this.vueWebsocket.getFriendRequest(LocalStore.getFriendRequestVersion())

    // 初始拉取消息列表
    // this.vueWebsocket.pullMessage(lastMessageSeq, 0, 0, LocalStore.getSendMessageCount())
    // LocalStore.setLastMessageSeq(msgContent.messageHead)
  }
}
