import AbstractMessageHandler from './abstractMessageHandler'
import { PUBLISH, MN } from '@/config'
import LocalStore from '@/websocket/store/localstore'

export default class NotifyMessageHandler extends AbstractMessageHandler {
  match(proto) {
    return proto.signal === PUBLISH && proto.subSignal === MN
  }

  processMessage(proto) {
    try {
      let notify = JSON.parse(proto.content)
      // 更新 messageHead
      LocalStore.resetSendMessageCount()
      LocalStore.setLastMessageSeq(notify.messageHead)
      this.vueWebsocket.pullMessage(notify.messageHead, notify.type, 1)
    } catch (r) {
      console.error('消息解析出错', { handler: 'NotifyMessageHandler', ...proto })
    }
  }
}
