/**
 *
 * websocket 消息 JSON 数据封装
 *
 */
import LocalStore from '@/websocket/store/localstore'

export default class WebSocketProtoMessage {
  version = 1
  signal = 'PUBLISH'
  subSignal = 'NONE'
  messageId = 0
  sessionId = LocalStore.getSelectTarget()
  toUserId = LocalStore.getSelectFriendId()
  toId = sessionStorage.getItem('friend-id')
  msgType = 1

  constructor(signal) {
    this.signal = signal
  }

  setVersion(version) {
    this.version = version
  }

  setSignal(signal) {
    this.signal = signal
  }

  setSubSignal(subSignal) {
    this.subSignal = subSignal
  }

  setMessageId(messageId) {
    this.messageId = messageId
  }

  setContent(text, url) {
    this.content = {
      text,
      url, // 图片或文件的链接
    }
  }

  setSessionId(sessionId) {
    this.sessionId = sessionId
  }

  setMsgType(msgType) {
    this.msgType = msgType
  }

  // 格式化消息内容
  toJson() {
    return JSON.stringify({
      version: this.version, // 版本号
      signal: this.signal, // 信令
      subSignal: this.subSignal, // 服务暂不支持子信令
      messageId: this.messageId || Date.now(), // 消息ID
      payload: {
        sessionId: this.sessionId, // 会话ID
        // sessionId: '19_21', // 会话ID
        // from: '123', // 发送者ID
        to: this.toId, // 接收者ID
        msgType: this.msgType, // 消息类型: 1-系统；2-文字；3-图片；4-文件；5-语音 6-视频
        content: this.content,
      },
    })
  }
}
