import MessageContentType from '../messageContentType'

import GroupNotificationContent from './groupNotification'
import StringUtils from '@/websocket/utils/StringUtil'
import webSocketCli from '@/websocket/websocketcli'

export default class CreateGroupNotification extends GroupNotificationContent {
    creator = '';
    groupName = '';

    constructor(creator, groupName) {
      super(MessageContentType.CreateGroup_Notification)
      this.creator = creator
      this.groupName = groupName
    }

    formatNotification() {
      if (this.fromSelf) {
        return '您创建了群组 ' + this.groupName
      } else {
        return webSocketCli.getDisplayName(this.creator) + ' 创建了群组 ' + this.groupName
      }
    }

    encode() {
      let payload = super.encode()
      let obj = {
        g: this.groupId,
        n: this.groupName,
        o: this.creator,
      }
      payload.binaryContent = StringUtils.utf8ToB64(JSON.stringify(obj))
      return payload
    }

    decode(payload) {
      super.decode(payload)
      let json = StringUtils.b64ToUtf8(payload.binaryContent)
      let obj = JSON.parse(json)
      this.groupId = obj.g
      this.creator = obj.o
      this.groupName = obj.n
    }
}
