import AbstractMessageHandler from './abstractMessageHandler'
import { PUB_ACK, CONNECT_FAIL, ERR } from '@/config'
import LocalStore from '@/websocket/store/localstore'
import Logger from '../utils/logger'
import MessageStatus from '../message/messageStatus'

export default class SendMessageHandler extends AbstractMessageHandler {
  match(proto) {
    return proto.signal === PUB_ACK || proto.signal === CONNECT_FAIL || proto.signal === ERR
    // return proto.signal == PUB_ACK && proto.subSignal == MS
  }

  processMessage(parameter) {
    let isFail = parameter.signal === CONNECT_FAIL || parameter.signal === ERR || parameter.subSignal === 'fail' || parameter.content === ''
    let proto = {
      content: {
        messageId: parseInt(Math.random() * 1000000),
        timestamp: Date.now(),
      },
      messageId: parameter.messageId,
      signal: parameter.signal,
    }
    Logger.log('sendMessage Handler messageId ' + proto.messageId)
    if (this.vueWebsocket.resolvePromiseMap.has(proto.messageId)) {
      let promiseReslove = this.vueWebsocket.resolvePromiseMap.get(proto.messageId)
      if (!isFail) {
        let content = proto.content
        this.vueWebsocket.sendAction('updateProtoMessageUid', {
          messageId: promiseReslove.protoMessageId,
          messageUid: content.messageId,
        })
        // update messageStatus
        this.vueWebsocket.sendAction('updateMessageStatus', {
          messageId: promiseReslove.protoMessageId,
          status: MessageStatus.Sent,
        })
        LocalStore.updateSendMessageCount() // 记录消息发送条数
        promiseReslove.resolve('success')
      } else {
        this.vueWebsocket.sendAction('updateMessageStatus', {
          messageId: promiseReslove.protoMessageId,
          status: MessageStatus.SendFailure,
        })
        promiseReslove.resolve('fail')
      }
      clearTimeout(promiseReslove.timeoutId)
      this.vueWebsocket.resolvePromiseMap.delete(proto.messageId)
    }
  }
}
