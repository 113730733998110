import AbstractMessageHandler from './abstractMessageHandler'
import { PUBLISH } from '@/config'
import ProtoMessage from '../message/protomessage'
import LocalStore from '@/websocket/store/localstore'
import MessageConfig from '../message/messageConfig'
import ChatManager from '../chatManager'
import store from '@/store'
export default class ReceiveMessageHandler extends AbstractMessageHandler {
  conversations = [];
  match(proto) {
    return proto.signal === PUBLISH
  }

  processMessage(parameter) {
    let payload = parameter.payload
    // console.log(payload, 'payload')
    let isMessageId = payload.content.text && payload.content.text.messageId
    let message = store.state.messages || []
    let currentMessage = message.find(v => v.target === payload.sessionId)
    let is
    currentMessage && (is = currentMessage.protoMessages.find(v => v.messageId === isMessageId))
    if (payload.messageType !== 22) {
      if (is) return false // 阻止自己接收
    }
    let proto = {
      messageId: parameter.messageId,
      signal: parameter.signal,
      content: {
        current: (Math.random() * 10000000).toString(),
        head: (Math.random() * 10000000).toString(),
        messageCount: 1,
        messageResponseList: [
          {
            content: payload.content.text.content || payload.content.text,
            conversationType: payload.content.text.conversationType,
            direction: payload.content.text.direction,
            from: payload.from,
            line: payload.content.text.line,
            messageId: parameter.messageId,
            status: payload.content.text.status,
            target: payload.content.text.target,
            timestamp: payload.content.text.timestamp,
            fromName: payload.fromName || payload.content.text.fromName || '',
            toId: payload.to || payload.from || '',
            type: payload.content.text.type,
            sessionName: payload.sessionName,
          },
        ],
      },
    }
    LocalStore.resetSendMessageCount() // 设置未读为0
    // 设置上一次接收消息 sessionId + '-' messageId
    let lastMessageId = sessionStorage.getItem('lastMessageId') || '0'
    let newMessageId = payload.sessionId + '-' + payload.messageId
    if (lastMessageId === newMessageId) {
      return false
    } else {
      sessionStorage.setItem('lastMessageId', newMessageId)
    }
    let content = proto.content
    if (content.messageCount === 0) {
      this.vueWebsocket.sendAction('changeEmptyMessageState', true)
    } else {
      this.vueWebsocket.sendAction('changeEmptyMessageState', false)
    }
    for (let protoMessage of content.messageResponseList) {
      let sessionId = protoMessage.target
      let fromName = protoMessage.fromName
      let toId = protoMessage.toId
      let type = protoMessage.type
      let sessionName = protoMessage.sessionName
      let protoMessage1 = ProtoMessage.toProtoMessage(protoMessage)
      protoMessage1.target = sessionId
      protoMessage1.fromName = fromName
      protoMessage1.toId = toId
      protoMessage1.type = type
      protoMessage1.sessionName = sessionName
      if (MessageConfig.isDisplayableMessage(protoMessage1)) {
        this.addProtoMessage(protoMessage1)
      }
      ChatManager.onReceiveMessage(protoMessage1)
    }
    // 如果是转交会话的逻辑
    if (payload.content.text.content.type === 98) {
      this.vueWebsocket.sendAction('changeSelfPerson', payload)
    }
    // 正在输入中的逻辑
    if (payload.content.text.content.type === 1000) {
      this.vueWebsocket.sendAction('changeInputing', payload)
    } else {
      // 收到消息取消正在输入
      this.vueWebsocket.sendAction('setInputing', false)
    }
    this.vueWebsocket.sendAction('changetFirstLogin', false)
  }

  addProtoMessage(protoMessage) {
    this.vueWebsocket.sendAction('addProtoMessage', protoMessage)
  }
}
