import MessageContent from './messageContent'
import MessageContentType from './messageContentType'

export default class TextMessageContent extends MessageContent {
  content

  constructor(content, type, mentionedType = 0, mentionedTargets = []) {
    super(type || MessageContentType.Text, mentionedType, mentionedTargets)
    this.content = content
  }

  digest() {
    return this.content
  }

  encode() {
    let payload = super.encode()
    payload.searchableContent = this.content
    return payload
  }

  decode(payload) {
    super.decode(payload)
    this.content = payload.searchableContent
  }
}
