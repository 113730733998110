/* eslint-disable */
export default class MessageContentType {
  // 基本消息类型
  static Unknown = 0 // 未知
  static Text = 1 // 文本
  static Voice = 2 // 语言
  static Image = 3 // 图片
  static Location = 4 // 位置
  static File = 5 // 文件
  static Video = 6 // 视频
  static Sticker = 7 // 表情消息
  static ImageText = 8 // 图片表情
  static P_Text = 9  // 系统消息
  // 卡片类型消息
  static CardChannel = 10
  static CardOrder = 11
  static CardInquiry = 12
  static QuotedPrice = 13
  static CardQueryChannel = 14 // 直客询价卡片

  static PromptViolation = 22 // 错误消息提示

  // 正在输入
  static Inputing = 1000
  // 提醒消息
  static RecallMessage_Notification = 80
  static Tip_Notification = 90
  static Typing = 91
  // 提醒订单消息
  static System = 99 // 系统消息(包括订单信息)
  static Transform = 98
  // 群相关消息
  static CreateGroup_Notification = 104
  static AddGroupMember_Notification = 105
  static KickOffGroupMember_Notification = 106
  static QuitGroup_Notification = 107
  static DismissGroup_Notification = 108
  static TransferGroupOwner_Notification = 109
  static ChangeGroupName_Notification = 110
  static ModifyGroupAlias_Notification = 111
  static ChangeGroupPortrait_Notification = 112

  static MuteGroupMember_Notification = 113
  static ChangeJoinType_Notification = 114
  static ChangePrivateChat_Notification = 115
  static ChangeSearchable_Notificaiton = 116
  static SetGroupManager_Notification = 117
  static VOIP_CONTENT_TYPE_START = 400
  static VOIP_CONTENT_TYPE_END = 402
  static VOIP_CONTENT_TYPE_ACCEPT = 401
  static VOIP_CONTENT_TYPE_SIGNAL = 403
  static VOIP_CONTENT_TYPE_MODIFY = 404
  static VOIP_CONTENT_TYPE_ACCEPT_T = 405
}
/* eslint-enable */
