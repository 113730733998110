// 路由页面列表
export default [
  {
    path: '/',
    component: () => import('@/views/Layout/Index'),
    // redirect: 'chat',
    children: [
      {
        path: '',
        name: 'chat',
        component: () => import('@/views/chat/Index'),
        meta: {
          needAuth: true,
          title: '客服系统',
        },
      },
      {
        path: '/chat-service',
        name: 'chat-service',
        component: () => import('@/views/chatService/index'),
        meta: {
          needAuth: true,
          title: '售后客服',
        },
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('@/components/Message'),
        meta: {
          needAuth: true,
          title: '聊天记录',
        },
      },
    ],
  },
  {
    path: '/*',
    redirect: '/',
  },
]
