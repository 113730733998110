import store from '@/store'
import router from '@/router'

// 是否登录
export function isLogin() {
  return !!localStorage.getItem('token') && !!localStorage.getItem('user-type')
}

// 转到登录
export function login() {
  router.push('/login')
}

// 设置登录态
export function setLogin({ token, userType }) {
  localStorage.setItem('token', token)
  localStorage.setItem('user-type', userType)
  store.commit('upLoginState', { token, userType })
}

// 清除登录态
export function clearLogin() {
  localStorage.removeItem('token')
  localStorage.removeItem('user-type')
  localStorage.clear()
  store.commit('upLoginState')
}

// 退出登录
export function logout(callback = () => {}) {
  clearLogin()
  callback()
}

// TODO:获取默认头像
export function getAvatar(flag) {
  if (localStorage.getItem('system-type') === 'HHY') {
    return require('@/assets/chat/huohaoyun.png')
  } else {
    if (flag === 'zy') {
      return require('@/assets/chat/avatar.png')
    } else {
      return require('@/assets/chat/kjhaoyun.png')
    }
  }
}
