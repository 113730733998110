import Vue from 'vue'
import VueRouter from 'vue-router'
import stat from 'vue-stat'
// import { isLogin, login } from '@/common/wjtools'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  linkActiveClass: 'active', // 链接激活的类名
  routes,
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 登录权限控制
  // if (to.meta.needAuth && !isLogin()) {
  //   let path = from.meta.needAuth ? '/' : from.path
  //   next({
  //     replace: true,
  //     path,
  //   })
  //   login(() => {
  //     router.push({
  //       path: to.path,
  //       params: to.params,
  //       query: to.query,
  //     })
  //   })
  // }

  next()
})

// 全局后置钩子
router.afterEach(to => {
  // window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 百度页面统计
  stat.trackPageview(to.fullPath)
})

export default router
