export default class CallEndReason {
    static reasonUnknown = 'unknown';
    static reasonBusy = 'busy';
    static reasonSignalError = 'signalError';
    static reasonHangup = 'hangup';
    static reasonMediaError = 'mediaError';
    static reasonRemoteHangup = 'remoteHangup';
    static reasonOpenCameraFailure = 'openCameraError';
    static reasonTimeout = 'timeout';
    static reasonAcceptByOtherClient = 'acceptByOtherClient';
    static reasonAllLeft = 'allLeft';
}
