// 接口列表
import { wwwUrl } from '@/config'
import { http, getQuery } from '@/common/wjtools'
const { isManageSign } = getQuery(location.href)
const wwwApi = wwwUrl + 'api'
const wwwOpt = { isWww: true }
const mockurl = 'https://rap2.17haoyun.cn/app/mock/88/' // eslint-disable-line

// 登录
export const login = params => http.post('/im-api/user/login', params, { notCheckLogin: true })
// 请求会话列表 | get
export const getConversationList = params => http.get('/im-api/im/session/list-by-user', params)
// 请求会话列表分页 | get
export const getConversationListByPage = params => http.get('/im-api/im/session/page', params)
// 新增会话 | post
// export const createConversation = params => http.post('/im-api/im/session/create', params)
// 创建用户会话 联营
export const createConversation = params => http.post('/im-api/im/session', params)
// 创建用户会话 自营
export const imSession = params => http.post('/kjhy-cs-bff/im/session', params, wwwOpt)

// 新增服务商与运力商的会话 | post
export const createSupplierConversation = params => http.post('/im-api/im/session/create-supplier', params)
// 点击会话请求会话历史记录数据 | post
// 管理端
export const getIsManageSignConversationHistory = params => http.post('/im-api/odin/im/session/messages', params)
// 非管理端
export const getConversationHistory = params => http.post(isManageSign ? '/im-api/odin/im/session/messages' : '/im-api/im/session/messages', params)
// 取消未读消息 | post
export const clearUnreadNum = params => http.post('/im-api/im/session/clearUnreadNum', params)
// 获取阿里云签名 | get
export const getOssSign = params => http.get('/im-api/im/oss/sign', params)
// 获取用户信息
export const getUserInfo = params => http.get('/im-api/im/user/info', params)
// 管理端-获取用户信息
export const managerGetUserInfo = params => http.get('/im-api/odin/im/session/vip', params)
// 获取chat的ws重连时长
export const getReconnectTime = params => http.get('/im-api/im/config/heartbeat', params)
// 快捷回复模板--删除
export const deleteTemplate = id => http.del(`/im-api/im/fast-reply/template/${id}`)
// 快捷回复模板--新增
export const addTemplate = params => http.put('/im-api/im/fast-reply/template/add', params)
// 快捷回复模板--编辑
export const editTemplate = params => http.put('/im-api/im/fast-reply/template/edit', params)
// 快捷回复模板--查看所有
export const findTemplate = params => http.get('/im-api/im/fast-reply/template/list')

// 自动回复-获取列表|get
export const getAutoReplyList = params => http.get('/im-api/im/autoanswer/list', params, { abortPrev: true })

// 自动回复-保存|post
export const saveAutoReply = params => http.post('/im-api/im/autoanswer/save', params)

// 自动回复-删除|post
export const deleteAutoReply = params => http.post('/im-api/im/autoanswer/delete', params)

// 自动回复-获取关键字近似词|get
export const getSimilarWord = params => http.get('/im-api/im/autoanswer/similarWord', params, { abortPrev: true })

// 适配管理端-获取时间戳|get
export const getReadTimeStemp = params => http.get('/im-api/odin/im/session/read', params)

// 修改对方备注名称
export const otherName = params => http.post('/im-api/im/session/other/name', params)

// 获取备注名
export const getMarkName = params => http.get('/im-api/im/session/get-mark-name', params)

// ====================================================================== 服务商端 直客端
// 个人中心-订单详情|get
export const getOrderDetail = (orderId, params) => http.get(`${wwwApi}/kjsc-order/order/orderDetail/${orderId}`, params, wwwOpt)
// 报价-新版获取报价详情 | get
export const queryOfferDetail = params => http.get(`${wwwApi}/kjhy-price/inquiry/query-transport-scheme-detail`, params, wwwOpt)
export const queryOfferDetailV2 = params => http.get(`${wwwApi}/kjsc-solution/inquiry/query-transport-scheme-detail/v2`, params, wwwOpt)
// 通过城市id 目的地code查询城市和目的国的全部信息
export const cityInfo = params => http.post(`${wwwApi}/kjsc-basedata/h5/out/country/city/info`, params, wwwOpt)

// 首页-起运城市|get
export const chineseCityList = params => http.get(`${wwwApi}/kjsc-basedata/h5/out/all/chineseCity`, params, wwwOpt)

export const getFbaListParams = params => http.get(`${wwwApi}/kjsc-transportation/h5/me/fba-warehouse/select-box`, params, wwwOpt)

// 根据起运城市目的国家查询（tree)|get
export const getCountryTree = params => http.get(`${wwwApi}/kjhy-supplier/h5/out/channel-country/by-city-id`, params, wwwOpt)

// 公用方法-文件上传|post
export const uploadFileCommon = (params, config) => http.post(`${wwwApi}/kjsc-basedata/h5/me/upload-file`, params, config)

// 根据关键词-洲国家模糊查询|get
export const getCitiesByKeywords = params => http.get(`${wwwApi}/kjhy-supplier/h5/out/fuzzy-query-country/by-city-id`, params, wwwOpt)

// 获取商品列表列表|get
export const getSkuList = params => http.get(`${wwwApi}/kjhy-order/h5/me/product/query`, params, wwwOpt)
// 报价页-获取运输方案库列表
export const getRouteParentList = params => http.post(`${wwwApi}/kjhy-supplier/im/channel-lib/list`, params, wwwOpt)
// 报价页面-获取运输方式|get
export const getSaleTransList = (params) => http.post(`${wwwApi}/kjhy-supplier/h5/me/sale-channel/list`, params, wwwOpt)
// 获取询价内容
export const priceDemand = (id, params) => http.get(`${wwwApi}/kjhy-cs-bff/im/price/demand/${id}`, params, wwwOpt)

// 获取询价内容 管理端
export const priceDemandOdin = (id, params) => http.get(`${wwwApi}/kjhy-cs-bff/odin/im/price/demand/${id}`, params, wwwOpt)
// 保存询价需求
export const addDemand = params => http.post(`${wwwApi}/kjhy-cs-bff/im/price/add-demand`, params, wwwOpt)
// 发布询价需求
export const publishDemand = params => http.post(`${wwwApi}/kjhy-cs-bff/im/price/publish-demand`, params, wwwOpt)
// 获取询价回复内容
export const demandReply = (id, params) => http.get(`${wwwApi}/kjhy-cs-bff/im/price/demand-reply/{id}`, params, wwwOpt)

// 下单页--仓位选择
export const schemeStoresInfo = (params) => http.get(`${wwwApi}/kjsc-solution/inquiry/scheme-stores-info`, params, wwwOpt)

// 服务商线路推荐
export const queryHomeSchemeListBylibid = (params) => http.post(`${wwwApi}/kjsc-solution/inquiry/query-home-scheme-list-bylibid/im-recommend`, params, wwwOpt)

// IM 窗口订单列表
export const getImOrderList = params => http.get(`${wwwApi}/kjsc-order/h5/me/im-orders`, params, wwwOpt)

// ======================================================================
export const conversationToTop = (params) => http.post('/im-api/im/session/setTop', params)

export const createSnapshot = (params) => http.get(`${wwwApi}/kjsc-solution/transport/sale-scheme/snapshot`, params, wwwOpt)

export const getSnapshot = (params) => http.get(`${wwwApi}/kjsc-solution/h5/transport/snapshot/get-by-id`, params, wwwOpt)

// 消息中心-获取未读数|get
export const getUnreadCount = (params) => http.get('/im-api/notify/totalNoRead', params)

// IM-行为通知
export const crmNotify = params => http.post('/kjsc-user/user/operate/notify', params, wwwOpt)

// IM聊天-获取服务商查货代的地址
export const getAgentUrl = params => http.get('/im-api/get-shipping-agent-url', params, wwwOpt)
